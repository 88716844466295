import { track } from "@amplitude/analytics-browser";
import { DesktopLayout } from "@components/layouts";
import { DesktopStars } from "@components/stars";
import { CAROUSEL_CONFIG } from "common/config";
import { CURATION_CTA_LINK, CURATION_PAGE_DATA } from "common/data";
import globalImages from "common/images";
import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

export const CurationDesktop = () => {
  const navigate = useNavigate();
  const slider = useRef<any>(null);

  const width = window.innerWidth;

  return (
    <DesktopLayout>
      <div
        className="bg-ivory flex flex-col items-center w-screen"
        style={{ marginLeft: (1280 - width) / 2 }}
      >
        <div className="w-[1280px] px-[48px] pt-[72px] pb-[120px]">
          {/* banner */}
          <div className="bg-[url('./assets/images/curation-banner-background.png')] bg-cover h-[357px] rounded-[32px] flex flex-col items-center justify-center mb-[120px]">
            <div className="text-[52px] text-white font-bold tracking-tight leading-tall mb-[20px]">
              <FormattedMessage id="curation.banner.title" />
            </div>
            <div className="text-[18px] text-white font-light tracking-tight leading-short mb-[48px] w-[350px] text-center">
              <FormattedMessage id="curation.banner.text" />
            </div>
            <button
              onClick={() => {
                track("cta_curation");
                window.open(CURATION_CTA_LINK);
              }}
              className="rounded-[100px] bg-white px-[16px] py-[8px] flex items-center gap-x-[4px]"
            >
              <div className="text-[12px] text-gray-90 font-medium tracking-tight leading-short">
                <FormattedMessage id="curation.banner.cta" />
              </div>
              <img
                src={globalImages.arrow.circle.right}
                className="w-[12px] h-[12px]"
                alt=""
              />
            </button>
          </div>

          {/* emerging products */}
          <div className="mb-[120px]">
            <div className="text-center text-[32px] text-gray-90 font-medium leading-tall tracking-tight mb-[48px]">
              <FormattedMessage id="curation.emerging.title" />
            </div>
            <div className="relative">
              <Slider ref={slider} {...CAROUSEL_CONFIG.curation.desktop}>
                {CURATION_PAGE_DATA.emergingProducts.map((product, index) => (
                  <div key={index} className="rounded-[32px]">
                    <div className="flex h-[460px]">
                      <button
                        onClick={() => {
                          if (product.productId) {
                            track(`curation-emerging-${index + 1}`);
                            navigate({
                              pathname: "/product",
                              search: `?id=${product.productId}`,
                            });
                          }
                        }}
                        className="text-left min-w-[500px] bg-white flex flex-col p-[48px] rounded-l-[32px]"
                      >
                        <div className="w-full mb-[16px] flex items-center justify-between">
                          <span className="text-[20px] text-gray-70 leading-tall tracking-tight font-light">
                            {product.brand}
                          </span>
                          <img
                            src={globalImages.icon.arrowRight}
                            className="w-[28px] h-[28px]"
                            alt=""
                          />
                        </div>
                        <div className="text-[32px] text-gray-90 font-medium tracking-tight leading-tall mb-[25px] min-h-[76px]">
                          {product.title}
                        </div>
                        <div className="flex items-center">
                          <DesktopStars rating={product.rating} />
                          <span className="text-[14px] text-gray-70 font-medium leading-short tracking-tight">
                            {product.rating}
                          </span>
                        </div>

                        <div className="h-[1px] bg-gray-10 my-[36px]" />

                        <div className="text-[14px] font-light tracking-tight leading-short text-gray-70">
                          {product.description
                            ?.split("\n\n")
                            .map((paragraph, index) => (
                              <div key={`paragraph-${index}`}>
                                {index > 0 && <br />}
                                {paragraph.split("\n").map((line, index) => (
                                  <div key={`line-${index}`}>{line}</div>
                                ))}
                              </div>
                            )) ?? ""}
                        </div>
                      </button>
                      <img
                        src={product.image}
                        className="min-w-[684px] object-cover rounded-r-[32px]"
                        alt=""
                      />
                    </div>
                  </div>
                ))}
              </Slider>
              <div className="absolute right-[32px] bottom-[32px] w-[60px] bg-white opacity-50 gap-x-[4px] flex items-center justify-center rounded-[100px]">
                <button
                  onClick={() => slider?.current?.slickPrev()}
                  className="w-[24px] h-[24px]"
                >
                  <img src={globalImages.chevron.left} alt="" />
                </button>
                <button
                  onClick={() => slider?.current?.slickNext()}
                  className="w-[24px] h-[24px]"
                >
                  <img src={globalImages.chevron.right} alt="" />
                </button>
              </div>
            </div>
          </div>

          {/* influencers' pick */}
          <div className="mb-[120px]">
            <div className="text-center text-[32px] text-gray-90 font-medium leading-tall tracking-tight mb-[16px]">
              <FormattedMessage id="curation.pick.title" />
            </div>
            <div className="text-center text-[20px] text-gray-70 font-light leading-tall tracking-tight mb-[48px]">
              <FormattedMessage id="curation.pick.subtitle" />
            </div>
            <div className="flex gap-x-[16px]">
              <div className="flex-1">
                <div className="flex gap-x-[16px] mb-[24px]">
                  {CURATION_PAGE_DATA.influencersPick
                    .slice(0, 2)
                    .map((product, index) => (
                      <button
                        onClick={() => {
                          if (product.productId) {
                            track(`curation-influencer-${index + 1}`);
                            navigate({
                              pathname: "/product",
                              search: `?id=${product.productId}`,
                            });
                          }
                        }}
                        key={index}
                      >
                        <div className="flex flex-col text-left">
                          <img
                            src={product.image}
                            className="mb-[12px]"
                            alt=""
                          />
                          <div className="px-[4px] flex flex-col gap-y-[4px]">
                            <div className="text-[12px] font-light text-gray-70 leading-short tracking-tight">
                              {product.brand}
                            </div>
                            <div className="text-[14px] font-light text-gray-90 leading-short tracking-tight">
                              {product.title}
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                </div>
                <img src={globalImages.curation[0]} alt="" />
              </div>
              <div className="flex-1">
                <img
                  src={globalImages.curation[1]}
                  className="mb-[24px]"
                  alt=""
                />
                <div className="flex gap-x-[16px]">
                  {CURATION_PAGE_DATA.influencersPick
                    .slice(2, 4)
                    .map((product, index) => (
                      <button
                        onClick={() => {
                          if (product.productId) {
                            track(`curation-influencer-${index + 3}`);
                            navigate({
                              pathname: "/product",
                              search: `?id=${product.productId}`,
                            });
                          }
                        }}
                        key={index}
                      >
                        <div className="flex flex-col text-left">
                          <img
                            src={product.image}
                            className="mb-[12px]"
                            alt=""
                          />
                          <div className="px-[4px] flex flex-col gap-y-[4px]">
                            <div className="text-[12px] font-light text-gray-70 leading-short tracking-tight">
                              {product.brand}
                            </div>
                            <div className="text-[14px] font-light text-gray-90 leading-short tracking-tight">
                              {product.title}
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>

          {/* top selling brand */}
          <div className="mb-[120px]">
            <div className="text-center text-[32px] text-gray-90 font-medium leading-tall tracking-tight mb-[48px]">
              <FormattedMessage id="curation.top.title" />
            </div>
            <div className="flex flex-col gap-y-[36px]">
              {CURATION_PAGE_DATA.monthlyTop.map((brand, index) => (
                <div className="flex justify-between" key={index}>
                  <div className="pt-[8px]">
                    <div className="text-[18px] text-primary font-light leading-short tracking-tight mb-[16px]">
                      <FormattedMessage id="curation.top.prefix" /> {index + 1}
                    </div>
                    <img
                      src={brand.logo}
                      className="h-[30px] mb-[36px]"
                      alt=""
                    />
                    <button
                      onClick={() => {
                        if (brand.brandId) {
                          track(`curation-brand-top-${index + 1}`);
                          navigate({
                            pathname: "/brand",
                            search: `?id=${brand.brandId}`,
                          });
                        }
                      }}
                      className="px-[16px] py-[8px] bg-white rounded-[100px] flex items-center gap-x-[4px]"
                    >
                      <div className="text-[12px] text-gray-90 font-medium leading-short tracking-tight">
                        <FormattedMessage id="curation.top.button" />
                      </div>
                      <img
                        src={globalImages.arrow.circle.right}
                        className="w-[12px] h-[12px]"
                        alt=""
                      />
                    </button>
                  </div>
                  <div className="flex gap-x-[16px]">
                    {brand.products.map((product, index) => (
                      <button
                        onClick={() => {
                          if (product.productId) {
                            navigate({
                              pathname: "/product",
                              search: `?id=${product.productId}`,
                            });
                          }
                        }}
                        key={index}
                        className="w-[184px] text-left"
                      >
                        <img src={product.image} className="mb-[12px]" alt="" />
                        <div className="text-[14px] text-gray-90 font-light leading-short tracking-tight px-[4px] min-h-[40px]">
                          {product.title}
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* md's pick */}
          <div className="mb-[60px]">
            <div className="text-center text-[32px] text-gray-90 font-medium leading-tall tracking-tight mb-[48px]">
              <FormattedMessage id="curation.md.title" />
            </div>
            <div className="flex gap-x-[16px]">
              {CURATION_PAGE_DATA.mdsPick.map((product, index) => (
                <button
                  onClick={() => {
                    if (product.productId) {
                      track(`curation-md-${index + 1}`);
                      navigate({
                        pathname: "/product",
                        search: `?id=${product.productId}`,
                      });
                    }
                  }}
                  key={index}
                  className="flex-1 rounded-[32px] pt-[48px] pb-[26px] bg-white flex flex-col items-center text-left"
                >
                  <img
                    src={product.image}
                    className="w-[236px] mb-[24px]"
                    alt=""
                  />
                  <div className="flex flex-col gap-y-[4px]">
                    <div className="text-[12px] text-gray-70 font-light leading-short tracking-tight">
                      {product.brand}
                    </div>
                    <div className="text-[16px] text-gray-90 font-light leading-short tracking-tight max-w-[230px] min-h-[44px]">
                      {product.title}
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>

          {/* cta */}
          <div className="sticky bottom-[48px] flex justify-center">
            <button
              onClick={() => navigate("/collection")}
              className="h-[56px] bg-primary w-[450px] rounded-[100px]"
            >
              <div className="text-[20px] text-white leading-tall tracking-tight">
                <FormattedMessage id="curation.cta" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </DesktopLayout>
  );
};
