import { track } from "@amplitude/analytics-browser";
import { MobileLayout } from "@components/layouts";
import { MobileStars } from "@components/stars";
import { getBrands } from "api/brands";
import { getProducts } from "api/products";
import { assetsBaseUrl } from "common/config";
import { CATALOG_DOWNLOAD_LINK } from "common/data";
import globalImages from "common/images";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

export const HomeMobile = () => {
  const navigate = useNavigate();
  const width = window.innerWidth;

  const [products, setProducts] = useState<Product[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);

  useEffect(() => {
    track("enter_home");
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const [products, brands] = await Promise.all([
        getProducts(),
        getBrands({ sort: "amazon" }),
      ]);
      if (products) {
        setProducts(products);
      }
      if (brands) {
        setBrands(brands);
      }
    };
    fetch();
  }, []);

  const _openCatalog = () => {
    window.open(CATALOG_DOWNLOAD_LINK);
  };

  return (
    <MobileLayout>
      {/* 배너 */}
      <div className="bg-[url('./assets/images/home-banner.png')] bg-cover bg-center w-full h-[356px] flex flex-col items-center justify-center text-center">
        <div className="flex flex-col text-[28px] w-[320px] font-bold tracking-tight leading-tall mb-[16px]">
          <span className="text-beige">
            <FormattedMessage id="home.banner.title-1" />
          </span>
          <span className="text-white">
            <FormattedMessage id="home.banner.title-2" />
          </span>
        </div>
        <div className="flex flex-col text-[16px] font-light tracking-tight leading-short text-white mb-[32px]">
          <span>
            <FormattedMessage id="home.banner.text-1" />
          </span>
          <span>
            <FormattedMessage id="home.banner.text-2" />
          </span>
        </div>
        <button
          onClick={() => {
            track("click_cta1");
            _openCatalog();
          }}
          className="h-[44px] bg-beige px-[16px] rounded-[200px] text-[14px] leading-short tracking-tight"
        >
          <FormattedMessage id="home.banner.cta" />
        </button>
      </div>

      {/* benefit */}
      <div className="flex flex-col gap-x-[16px] mb-[100px] bg-blue py-[64px]">
        <span className="bg-blue flex-1 flex flex-col items-center justify-center text-center px-[16px] py-[36px] gap-y-[16px]">
          <div className="text-[28px] font-medium tracking-tight leading-tall text-beige">
            <FormattedMessage id="home.benefit-1.title" />
          </div>
          <div className="text-[16px] font-light tracking-tight leading-short text-white">
            <FormattedMessage id="home.benefit-1.text" />
          </div>
        </span>

        <span className="bg-blue flex-1 flex flex-col items-center text-center justify-center px-[16px] py-[36px] gap-y-[16px]">
          <div className="text-[28px] font-medium tracking-[-0.64px] leading-[110%] text-[#E7DBCD]">
            <FormattedMessage id="home.benefit-2.title" />
          </div>
          <div className="text-[16px] font-light tracking-tight leading-short text-white">
            <FormattedMessage id="home.benefit-2.text" />
          </div>
        </span>

        <span className="bg-blue flex-1 flex flex-col items-center text-center justify-center px-[16px] py-[36px] gap-y-[16px]">
          <div className="text-[28px] font-medium tracking-[-0.64px] leading-[110%] text-[#E7DBCD]">
            <FormattedMessage id="home.benefit-3.title" />
          </div>
          <div className="text-[16px] font-light tracking-tight leading-short text-white">
            <FormattedMessage id="home.benefit-3.text" />
          </div>
        </span>
      </div>

      {/* best products */}
      <div className={`flex flex-col mb-[100px]`}>
        <div className="text-center text-[20px] font-bold text-gray-90 mb-[32px]">
          <FormattedMessage id="home.bestproducts.title" />
        </div>
        <div
          className="flex gap-x-[16px] mb-[32px] overflow-x-auto px-[16px]"
          style={{ maxWidth: width }}
        >
          {products.splice(0, 4).map((product, index) => (
            <button
              onClick={() => {
                navigate({
                  pathname: "/product",
                  search: `?id=${product.id}`,
                });
              }}
              key={index}
              className="min-w-[244px] bg-gray-10 rounded-[20px] p-[24px] flex flex-col justify-between"
            >
              {" "}
              <div className="flex flex-col gap-y-[16px] mb-[34px]">
                <div className="text-[16px] font-medium leading-short tracking-tight text-gray-90 line-clamp-2 text-left">
                  {product.name}
                </div>
                <div className="flex items-center gap-x-[4px]">
                  <MobileStars rating={product.rating} />
                  <span className="text-[12px] font-medium text-gray-70 tracking-tight">
                    {product.rating}
                  </span>
                </div>
              </div>
              <img
                src={assetsBaseUrl + product.images[0]}
                className="w-[200px] aspect-square mx-auto rounded-[8px]"
                alt=""
              />
            </button>
          ))}
        </div>
        <div className="w-full flex justify-center">
          <button
            className="px-[16px] py-[10px] rounded-[100px] bg-gray-90 text-white text-[14px] font-medium leading-short tracking-tight"
            onClick={() => {
              track("click_button1");
              navigate("/collection");
            }}
          >
            <FormattedMessage id="home.bestproducts.button" />
          </button>
        </div>
      </div>

      {/* our points */}
      <div
        className={`flex items-start bg-[#F1DFCC] rounded-[20px] aspect-[1.17] mb-[100px] bg-[url(./assets/images/home-points-background-mobile.png)] bg-cover bg-center mx-[16px]`}
      >
        <div className="flex flex-col gap-y-[8px] text-[14px] tracking-tight leading-short font-medium text-gray-90 px-[20px] py-[32px]">
          <span className="flex items-center gap-x-[8px]">
            <img
              src={globalImages.icon.check.circle.blue}
              className="w-[17px] h-[17px]"
              alt=""
            />
            <FormattedMessage id="home.points.text-1" />
          </span>
          <span className="flex items-center gap-x-[8px]">
            <img
              src={globalImages.icon.check.circle.blue}
              className="w-[17px] h-[17px]"
              alt=""
            />
            <FormattedMessage id="home.points.text-2" />
          </span>
          <span className="flex items-center gap-x-[8px]">
            <img
              src={globalImages.icon.check.circle.blue}
              className="w-[17px] h-[17px]"
              alt=""
            />
            <FormattedMessage id="home.points.text-3" />
          </span>
        </div>
      </div>

      {/* popular brands */}
      <div className="flex flex-col mb-[100px] items-center">
        <div
          className="flex grow gap-x-[8px] mb-[24px] px-[16px] overflow-x-auto"
          style={{ maxWidth: width }}
        >
          {brands.splice(0, 3).map((brand, index) => (
            <button
              onClick={() => {
                navigate({
                  pathname: "/brand",
                  search: `?id=${brand.id}`,
                });
              }}
              className="flex-1 min-w-[244px] h-[230px] rounded-[20px] flex justify-center items-center border border-gray-20"
              key={index}
            >
              <img
                src={assetsBaseUrl + brand.image}
                className="h-full rounded-[20px] w-full aspect-square"
                alt=""
              />
            </button>
          ))}
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col gap-y-[8px] text-center mb-[32px]">
            <div className="text-[18px] font-medium tracking-tight leading-short text-gray-90">
              <FormattedMessage id="home.brands.title" />
            </div>
            <div className="text-[14px] font-light leading-short tracking-tight text-gray-70">
              <FormattedMessage id="home.brands.description" />
            </div>
          </div>
          <div>
            <button
              className="bg-gray-90 text-white rounded-[100px] text-[14px] font-medium tracking-tight px-[16px] py-[10px]"
              onClick={() => {
                track("click_button2");
                navigate("/collection");
              }}
            >
              <FormattedMessage id="home.brands.button" />
            </button>
          </div>
        </div>
      </div>

      {/* unique values */}
      <div className="flex flex-col items-center mb-[100px]">
        <div className="text-[20px] font-bold leading-tall tracking-tight mb-[32px] text-gray-90">
          <FormattedMessage id="home.unique.title" />
        </div>
        <div className="flex flex-col text-center gap-y-[24px]">
          <span className="flex-1">
            <div className="text-[16px] font-medium text-primary leading-short tracking-tight mb-[8px]">
              <FormattedMessage id="home.unique-1.title" />
            </div>
            <div className="text-[12px] leading-short tracking-tight font-light text-gray-70 max-w-[200px]">
              <FormattedMessage id="home.unique-1.content" />
            </div>
          </span>
          <span className="flex-1">
            <div className="text-[16px] font-medium text-primary leading-short tracking-tight mb-[8px]">
              <FormattedMessage id="home.unique-2.title" />
            </div>
            <div className="text-[12px] leading-short tracking-tight font-light text-gray-70 max-w-[200px]">
              <FormattedMessage id="home.unique-2.content" />
            </div>
          </span>
          <span className="flex-1">
            <div className="text-[16px] font-medium text-primary leading-short tracking-tight mb-[8px]">
              <FormattedMessage id="home.unique-3.title" />
            </div>
            <div className="text-[12px] leading-short tracking-tight font-light text-gray-70 max-w-[200px]">
              <FormattedMessage id="home.unique-3.content" />
            </div>
          </span>
          <span className="flex-1">
            <div className="text-[16px] font-medium text-primary leading-short tracking-tight mb-[8px]">
              <FormattedMessage id="home.unique-4.title" />
            </div>
            <div className="text-[12px] leading-short tracking-tight font-light text-gray-70 max-w-[200px]">
              <FormattedMessage id="home.unique-4.content" />
            </div>
          </span>
        </div>
      </div>

      <div className="px-[16px] mb-[100px]">
        <div className="h-[248px] bg-[url('./assets/images/home-cta-background-mobile.png')] bg-cover rounded-[20px] flex flex-col items-center justify-center">
          <div className="text-[20px] text-white leading-tall tracking-tight text-center w-[270px] mb-[36px]">
            <FormattedMessage id="home.cta.text" />
          </div>
          <button
            onClick={() => {
              track("click_cta2");
              _openCatalog();
            }}
            className="h-[44px] px-[16px] bg-gray-90 rounded-[200px] text-white text-[14px] tracking-tight leading-short"
          >
            <FormattedMessage id="home.cta.button" />
          </button>
        </div>
      </div>
    </MobileLayout>
  );
};
