import { track } from "@amplitude/analytics-browser";
import { DesktopProductBox, DesktopReviewBox } from "@components/boxes";
import { DesktopLayout } from "@components/layouts";

import { DesktopStars } from "@components/stars";
import { getBrandProducts } from "api/brands";
import { getProductDetail, getProductReviews } from "api/products";
import { assetsBaseUrl } from "common/config";
import globalImages from "common/images";
import { addDays, format } from "date-fns";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";

export const ProductDesktop = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const productId = Number(searchParams.get("id"));

  const [product, setProduct] = useState<Product>();
  const [brandProducts, setBrandProducts] = useState<Product[]>([]);

  const [spreadDetails, setSpreadDetails] = useState<boolean>(false);
  const [reviews, setReviews] = useState<Review[]>([]);

  const [imageIndex, setImageIndex] = useState<number>(0);

  useEffect(() => {
    track("enter_product", { productId });
  }, [productId]);

  useEffect(() => {
    const fetch = async () => {
      const [productData, reviewData] = await Promise.all([
        getProductDetail(productId),
        getProductReviews(productId),
      ]);
      if (productData) {
        setProduct(productData);
        const brandProductsData = await getBrandProducts(productData.brand.id, {
          without: productId,
        });
        if (brandProductsData) {
          setBrandProducts(brandProductsData);
        }
      }
      if (reviewData) {
        setReviews(reviewData);
      }
    };

    fetch();
  }, [productId]);

  return (
    <DesktopLayout>
      {/* upper part */}
      <div className="pt-[72px] px-[48px] flex gap-x-[116px] bg-white">
        {/* left part */}
        <div className="justify-center">
          <div className="w-[684px] h-[684px] bg-white border border-gray-20 rounded-[25px] flex mb-[25px]">
            <img
              src={assetsBaseUrl + product?.images[imageIndex]}
              className="object-contain rounded-[25px]"
              alt=""
            />
          </div>
          <div className="flex items-center justify-center max-w-[684px] gap-x-[16px]">
            {product?.images.map((image: string, index: number) => (
              <button
                onClick={() => setImageIndex(index)}
                key={index}
                className="rounded-[12px] border border-gray-20 min-w-[84px] max-w-[84px] min-h-[84px] max-h-[84px] flex items-center justify-center"
              >
                <img
                  src={assetsBaseUrl + image}
                  className="rounded-[12px]"
                  alt=""
                />
              </button>
            ))}
          </div>
        </div>

        {/* right part */}
        <div className="py-[8px]">
          <button
            onClick={() =>
              navigate({
                pathname: "/brand",
                search: `?id=${product?.brand.id}`,
              })
            }
            className="font-light leading-short tracking-tight mb-[16px] text-gray-90"
          >
            {product?.brand.name}
          </button>
          <div className="text-[28px] font-medium leading-tall tracking-tight mb-[16px] text-gray-90">
            {product?.name}
          </div>
          <div className="flex gap-x-[4px] items-center mb-[36px]">
            <DesktopStars rating={product?.rating ?? 0} />
            <span className="text-[14px] font-medium tracking-tight leading-short text-gray-70">
              {product?.rating}
            </span>
          </div>
          <div className="font-light leading-short tracking-tight mb-[36px] text-gray-70">
            {product?.capacity}
          </div>
          <div className="flex items-center mb-[36px] text-gray-90">
            <span className="font-light tracking-tight leading-short mr-[24px]">
              <FormattedMessage id="product.MSRP.label" />
            </span>
            <span className="text-[32px] font-medium leading-tall tracking-tight">
              ${((product?.msrp ?? 0) / 1300).toFixed(2).toLocaleString()}
            </span>
          </div>

          <button
            onClick={() => {
              track("click_request", { productId });
              navigate({
                pathname: "/product/contact",
                search: `?id=${product?.id}`,
              });
            }}
            className="w-full h-[56px] rounded-[100px] font-medium tracking-tight text-white bg-primary mb-[24px]"
          >
            <FormattedMessage id="product.contact.label" />
          </button>

          {/* guarantee */}
          <div className="p-[24px] text-gray-70 text-[13px] rounded-[10px] bg-ivory">
            <div className="mb-[8px] text-[20px] text-primary font-medium leading-tall tracking-tight">
              <FormattedMessage id="product.guarantee.label" />
            </div>
            <div className="flex flex-col font-light leading-short tracking-tight">
              <div className="flex">
                <span className="mr-[4px] min-w-[16px] max-w-[16px] h-[16px]">
                  <img src={globalImages.icon.box} alt="" />
                </span>
                <span>
                  <span>
                    <FormattedMessage id="product.guarantee.text-1" />{" "}
                  </span>
                  <span>
                    <FormattedMessage id="product.guarantee.text-2" />{" "}
                    {product?.brand.name},{" "}
                  </span>
                  <span>
                    <FormattedMessage id="product.guarantee.text-3" />
                  </span>
                </span>
              </div>
              <br />
              <div className="flex">
                <span className="mr-[4px] min-w-[16px] max-w-[16px] h-[16px]">
                  <img src={globalImages.icon.box} alt="" />
                </span>
                <span>
                  <FormattedMessage id="product.guarantee.text-4" />
                </span>
              </div>
            </div>
          </div>

          {/* shipping */}
          <div className="px-[24px] py-[16px]">
            <div className="mb-[8px] text-[13px] font-medium leading-short tracking-tight text-gray-70">
              <FormattedMessage id="product.shipping.label" />
            </div>
            <div className="flex flex-col gap-y-[4px]">
              <div className="flex gap-x-[8px] text-gray-70">
                <span className="text-[14px] font-light leading-short tracking-tight">
                  •
                </span>
                <span className="text-[13px] leading-short tracking-tight">
                  <span className="font-light">
                    <FormattedMessage id="product.shipping.text-1" />{" "}
                  </span>
                  <span className="font-medium">7-18 days</span>
                </span>
              </div>
              <div className="flex gap-x-[8px] text-gray-70">
                <span className="text-[14px] font-light leading-short tracking-tight">
                  •
                </span>
                <span className="text-[13px] leading-short tracking-tight">
                  <span className="font-light">
                    <FormattedMessage id="product.shipping.text-2" />{" "}
                  </span>
                  <span className="font-medium">$ 5.00</span>
                </span>
              </div>
              <div className="flex gap-x-[8px] text-gray-70">
                <span className="text-[14px] font-light leading-short tracking-tight">
                  •
                </span>
                <div className="flex flex-col text-[13px] leading-short tracking-tight">
                  <span>
                    <span className="font-light">
                      <FormattedMessage id="product.shipping.text-3.1" />{" "}
                    </span>
                    <span className="font-medium">
                      {format(addDays(new Date(), 7), "MMM d, Y")} -{" "}
                      {format(addDays(new Date(), 18), "MMM d, Y")}
                    </span>
                  </span>
                  <span className="font-light">
                    <FormattedMessage id="product.shipping.text-3.2" />
                  </span>
                </div>
              </div>
              <div className="flex gap-x-[8px] text-error">
                <span className="text-[14px] font-light leading-short tracking-tight">
                  •
                </span>
                <span className="text-[13px] leading-short tracking-tight font-light">
                  <FormattedMessage id="product.shipping.text-4" />{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-[48px] py-[120px] bg-white">
        {/* details */}
        <div className="p-[48px] bg-gray-10 rounded-[20px] mb-[120px]">
          <div className="text-[32px] font-bold leading-tall tracking-tight mb-[36px] text-gray-90">
            <FormattedMessage id="product.detail.label" />
          </div>
          <div
            className={`font-light leading-short tracking-tight mb-[24px] text-gray-70 ${
              spreadDetails ? "" : "line-clamp-3"
            }`}
          >
            {product?.description?.split("\n\n").map((paragraph, index) => (
              <div key={`paragraph-${index}`}>
                {index > 0 && <br />}
                {paragraph.split("\n").map((line, index) => (
                  <div key={`line-${index}`}>{line}</div>
                ))}
              </div>
            )) ?? ""}
          </div>
          <div className="flex">
            <button
              className="mx-auto"
              onClick={() => setSpreadDetails(!spreadDetails)}
            >
              <img
                src={
                  spreadDetails
                    ? globalImages.chevron.up
                    : globalImages.chevron.down
                }
                className="w-[24px] h-[24px]"
                alt=""
              />
            </button>
          </div>
        </div>

        {/* reviews */}
        <div className="mb-[120px]">
          <div className="text-[32px] font-bold leading-tall tracking-tight mb-[36px] text-gray-90">
            <FormattedMessage id="product.reviews.label" />
          </div>
          <div className="flex gap-x-[16px] overflow-x-auto">
            {reviews.map((review, index) => (
              <DesktopReviewBox review={review} key={index} />
            ))}
          </div>
        </div>

        {/* more products */}
        <div>
          <div className="mb-[36px] flex justify-between items-center">
            <span className="text-[32px] font-bold leading-tall tracking-tight text-gray-90">
              <FormattedMessage id="product.more.label" /> {product?.brand.name}
            </span>
            <button
              onClick={() => {
                track("click_see_all");
                navigate({
                  pathname: "/brand",
                  search: `?id=${product?.brand.id}`,
                });
              }}
              className="font-light leading-short tracking- underline text-gray-70"
            >
              <FormattedMessage id="product.more.button" />
            </button>
          </div>
          <div className="flex gap-x-[16px]">
            {brandProducts.slice(0, 4).map((product, index) => (
              <DesktopProductBox product={product} key={index} />
            ))}
          </div>
        </div>
      </div>
    </DesktopLayout>
  );
};
