import { COOKIE_POLICY_LINK } from "common/data";
import globalImages from "common/images";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { DesktopFooter, MobileFooter } from "./footers";
import { DesktopHeader, MobileHeader } from "./headers";

interface LayoutProps {
  children?: React.ReactNode;
}

const DesktopLayout = ({ children }: LayoutProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modal, setModal] = useState<"main" | "detail">("main");

  const acceptNecessaryCookies = true;
  const [acceptFunctionalCookies, setAcceptFunctionalCookies] =
    useState<boolean>(false);
  const [acceptPerformanceCookies, setAcceptPerformanceCookies] =
    useState<boolean>(false);
  const [acceptTargetingCookies, setAcceptTargetingCookies] =
    useState<boolean>(false);

  useEffect(() => {
    const cookies = localStorage.getItem("accept-necessary-cookies");
    // check cookies
    if (cookies == null) {
      document.body.style.overflow = "hidden";
      setShowModal(true);
    }
  }, []);

  const _reset = () => {
    document.body.style.overflow = "visible";
    setShowModal(false);
    window.location.reload();
  };

  // set cookies
  const _acceptAllCookies = () => {
    localStorage.setItem("accept-necessary-cookies", "true");
    localStorage.setItem("accept-functional-cookies", "true");
    localStorage.setItem("accept-performance-cookies", "true");
    localStorage.setItem("accept-targeting-cookies", "true");
    _reset();
  };
  const _acceptNecessaryCookiesOnly = () => {
    localStorage.setItem("accept-necessary-cookies", "true");
    _reset();
  };
  const _onConfirm = () => {
    if (acceptNecessaryCookies) {
      localStorage.setItem("accept-necessary-cookies", "true");
    }
    if (acceptFunctionalCookies) {
      localStorage.setItem("accept-functional-cookies", "true");
    }
    if (acceptPerformanceCookies) {
      localStorage.setItem("accept-performance-cookies", "true");
    }
    if (acceptTargetingCookies) {
      localStorage.setItem("accept-targeting-cookies", "true");
    }
    _reset();
  };

  return (
    <div className="flex flex-col items-center justify-center bg-white min-h-screen">
      {showModal && (
        <div
          className="absolute w-screen h-screen py-[30px] top-0 left-0 flex justify-center items-center"
          style={{
            zIndex: Number.MAX_SAFE_INTEGER,
            backgroundColor: "rgb(25, 25, 25, 0.4)",
          }}
        >
          {modal === "main" ? (
            <div className="absolute bottom-[48px] left-[48px] bg-white rounded-[20px] px-[20px] py-[24px] max-w-[284px]">
              <div className="mb-[72px]">
                <div className="font-medium text-[14px] leading-short tracking-tight text-gray-90 mb-[16px]">
                  <FormattedMessage id="cookie.main.title" />
                </div>
                <div className="font-light text-[12px] tracking-tight leading-short text-gray-70">
                  <FormattedMessage id="cookie.main.description" />
                </div>
                <div className="font-light text-[12px] tracking-tight leading-short text-primary">
                  <button
                    onClick={() => window.open(COOKIE_POLICY_LINK)}
                    className="underline"
                  >
                    <FormattedMessage id="cookie.main.link" />
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-y-[6px]">
                <button
                  onClick={_acceptAllCookies}
                  className="rounded-[10px] bg-primary h-[40px] font-light text-[14px] leading-short tracking-tight text-white"
                >
                  <FormattedMessage id="cookie.main.button.accept-all.label" />
                </button>
                <button
                  onClick={() => setModal("detail")}
                  className="h-[40px] font-light text-[14px] leading-short tracking-tight text-gray-90"
                >
                  <FormattedMessage id="cookie.main.button.cookie-settings.label" />
                </button>
              </div>
            </div>
          ) : (
            <div className="bg-white h-[660px] rounded-[20px] w-[384px] px-[30px] pt-[24px] pb-[20px] relative flex flex-col">
              <div className="flex items-center justify-between mb-[32px] min-h-[32px]">
                <span className="font-medium text-[14px] leading-short tracking-tight text-gray-90">
                  <FormattedMessage id="cookie.detail.title" />
                </span>
                <button
                  onClick={_acceptAllCookies}
                  className="h-[32px] px-[20px] text-white text-[13px] font-light leading-short tracking-tight rounded-[10px] bg-primary"
                >
                  <FormattedMessage id="cookie.main.button.accept-all.label" />
                </button>
              </div>
              <div className="grow mb-[36px] overflow-y-auto flex flex-col gap-y-[20px]">
                <div className="flex items-start">
                  <button className="min-w-[20px] h-[20px] mr-[4px]">
                    <img
                      src={
                        acceptNecessaryCookies
                          ? globalImages.checkbox.square.active
                          : globalImages.checkbox.square.inactive
                      }
                      alt=""
                    />
                  </button>
                  <div className="flex flex-col">
                    <div className="text-[14px] font-medium leading-short tracking-tight text-gray-90 mb-[8px]">
                      <FormattedMessage id="cookie.detail-1.title" />
                    </div>
                    <div className="text-[12px] font-light leading-short tracking-tight text-gray-70">
                      <FormattedMessage id="cookie.detail-1.text" />
                    </div>
                  </div>
                </div>
                <div className="flex items-start">
                  <button
                    onClick={() =>
                      setAcceptFunctionalCookies(!acceptFunctionalCookies)
                    }
                    className="min-w-[20px] h-[20px] mr-[4px]"
                  >
                    <img
                      src={
                        acceptFunctionalCookies
                          ? globalImages.checkbox.square.active
                          : globalImages.checkbox.square.inactive
                      }
                      alt=""
                    />
                  </button>
                  <div className="flex flex-col">
                    <div className="text-[14px] font-medium leading-short tracking-tight text-gray-90 mb-[8px]">
                      <FormattedMessage id="cookie.detail-2.title" />
                    </div>
                    <div className="text-[12px] font-light leading-short tracking-tight text-gray-70">
                      <FormattedMessage id="cookie.detail-2.text" />
                    </div>
                  </div>
                </div>
                <div className="flex items-start">
                  <button
                    onClick={() =>
                      setAcceptPerformanceCookies(!acceptPerformanceCookies)
                    }
                    className="min-w-[20px] h-[20px] mr-[4px]"
                  >
                    <img
                      src={
                        acceptPerformanceCookies
                          ? globalImages.checkbox.square.active
                          : globalImages.checkbox.square.inactive
                      }
                      alt=""
                    />
                  </button>
                  <div className="flex flex-col">
                    <div className="text-[14px] font-medium leading-short tracking-tight text-gray-90 mb-[8px]">
                      <FormattedMessage id="cookie.detail-3.title" />
                    </div>
                    <div className="text-[12px] font-light leading-short tracking-tight text-gray-70">
                      <FormattedMessage id="cookie.detail-3.text" />
                    </div>
                  </div>
                </div>
                <div className="flex items-start">
                  <button
                    onClick={() =>
                      setAcceptTargetingCookies(!acceptTargetingCookies)
                    }
                    className="min-w-[20px] h-[20px] mr-[4px]"
                  >
                    <img
                      src={
                        acceptTargetingCookies
                          ? globalImages.checkbox.square.active
                          : globalImages.checkbox.square.inactive
                      }
                      alt=""
                    />
                  </button>
                  <div className="flex flex-col">
                    <div className="text-[14px] font-medium leading-short tracking-tight text-gray-90 mb-[8px]">
                      <FormattedMessage id="cookie.detail-4.title" />
                    </div>
                    <div className="text-[12px] font-light leading-short tracking-tight text-gray-70">
                      <FormattedMessage id="cookie.detail-4.text" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-[6px] min-h-[86px]">
                <button
                  onClick={_acceptNecessaryCookiesOnly}
                  className="h-[40px] rounded-[10px] bg-primary text-white font-light text-[14px] leading-short tracking-tight"
                >
                  <FormattedMessage id="cookie.detail.button.necessary.label" />
                </button>
                <button
                  onClick={_onConfirm}
                  className="h-[40px] text-gray-80 font-light text-[14px] leading-short tracking-tight"
                >
                  <FormattedMessage id="cookie.detail.button.confirm.label" />
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      <DesktopHeader />
      <div className="w-full h-[48px] bg-beige flex items-center justify-center text-[14px] tracking-tight leading-short font-medium text-gray-80">
        <FormattedMessage id="band-banner.text" />
      </div>
      <div className="grow flex flex-col max-w-[1280px]">
        <div className="grow">{children}</div>
      </div>
      <DesktopFooter />
    </div>
  );
};

const MobileLayout = ({ children }: LayoutProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modal, setModal] = useState<"main" | "detail">("main");

  const acceptNecessaryCookies = true;
  const [acceptFunctionalCookies, setAcceptFunctionalCookies] =
    useState<boolean>(false);
  const [acceptPerformanceCookies, setAcceptPerformanceCookies] =
    useState<boolean>(false);
  const [acceptTargetingCookies, setAcceptTargetingCookies] =
    useState<boolean>(false);

  useEffect(() => {
    const cookies = localStorage.getItem("accept-necessary-cookies");
    // check cookies
    if (cookies == null) {
      document.body.style.overflow = "hidden";
      setShowModal(true);
    }
  }, []);

  const _reset = () => {
    document.body.style.overflow = "visible";
    setShowModal(false);
    window.location.reload();
  };

  // set cookies
  const _acceptAllCookies = () => {
    localStorage.setItem("accept-necessary-cookies", "true");
    localStorage.setItem("accept-functional-cookies", "true");
    localStorage.setItem("accept-performance-cookies", "true");
    localStorage.setItem("accept-targeting-cookies", "true");
    _reset();
  };
  const _acceptNecessaryCookiesOnly = () => {
    localStorage.setItem("accept-necessary-cookies", "true");
    _reset();
  };
  const _onConfirm = () => {
    if (acceptNecessaryCookies) {
      localStorage.setItem("accept-necessary-cookies", "true");
    }
    if (acceptFunctionalCookies) {
      localStorage.setItem("accept-functional-cookies", "true");
    }
    if (acceptPerformanceCookies) {
      localStorage.setItem("accept-performance-cookies", "true");
    }
    if (acceptTargetingCookies) {
      localStorage.setItem("accept-targeting-cookies", "true");
    }
    _reset();
  };

  return (
    <div className="flex flex-col items-center justify-center bg-white min-h-screen">
      {showModal && (
        <div
          className="absolute w-screen h-screen py-[30px] top-0 left-0 flex justify-center items-center"
          style={{
            zIndex: Number.MAX_SAFE_INTEGER,
            backgroundColor: "rgb(25, 25, 25, 0.4)",
          }}
        >
          {modal === "main" ? (
            <div className="absolute bg-white rounded-[20px] px-[20px] py-[24px] max-w-[284px]">
              <div className="mb-[72px]">
                <div className="font-medium text-[14px] leading-short tracking-tight text-gray-90 mb-[18px]">
                  <FormattedMessage id="cookie.main.title" />
                </div>
                <div className="font-light text-[12px] tracking-tight leading-short text-gray-70">
                  <FormattedMessage id="cookie.main.description" />
                </div>
                <div className="font-light text-[12px] tracking-tight leading-short text-primary">
                  <button
                    onClick={() => window.open(COOKIE_POLICY_LINK)}
                    className="underline"
                  >
                    <FormattedMessage id="cookie.main.link" />
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-y-[6px]">
                <button
                  onClick={_acceptAllCookies}
                  className="rounded-[10px] bg-primary h-[40px] font-light text-[14px] leading-short tracking-tight text-white"
                >
                  <FormattedMessage id="cookie.main.button.accept-all.label" />
                </button>
                <button
                  onClick={() => setModal("detail")}
                  className="h-[40px] font-light text-[14px] leading-short tracking-tight text-gray-90"
                >
                  <FormattedMessage id="cookie.main.button.cookie-settings.label" />
                </button>
              </div>
            </div>
          ) : (
            <div className="bg-white h-[556px] rounded-[20px] max-w-[328px] px-[12px] py-[32px] relative flex flex-col">
              <div className="flex items-center justify-between mb-[24px] min-h-[32px] px-[4px]">
                <span className="font-medium text-[14px] leading-short tracking-tight text-gray-90">
                  <FormattedMessage id="cookie.detail.title" />
                </span>
                <button
                  onClick={_acceptAllCookies}
                  className="h-[32px] px-[20px] text-white text-[13px] font-light leading-short tracking-tight rounded-[10px] bg-primary"
                >
                  <FormattedMessage id="cookie.main.button.accept-all.label" />
                </button>
              </div>
              <div className="grow mb-[36px] overflow-y-auto flex flex-col gap-y-[20px]">
                <div className="flex items-start">
                  <button className="min-w-[20px] h-[20px] mr-[4px]">
                    <img
                      src={
                        acceptNecessaryCookies
                          ? globalImages.checkbox.square.active
                          : globalImages.checkbox.square.inactive
                      }
                      alt=""
                    />
                  </button>
                  <div className="flex flex-col">
                    <div className="text-[14px] font-medium leading-short tracking-tight text-gray-90 mb-[8px]">
                      <FormattedMessage id="cookie.detail-1.title" />
                    </div>
                    <div className="text-[12px] font-light leading-short tracking-tight text-gray-70">
                      <FormattedMessage id="cookie.detail-1.text" />
                    </div>
                  </div>
                </div>
                <div className="flex items-start">
                  <button
                    onClick={() =>
                      setAcceptFunctionalCookies(!acceptFunctionalCookies)
                    }
                    className="min-w-[20px] h-[20px] mr-[4px]"
                  >
                    <img
                      src={
                        acceptFunctionalCookies
                          ? globalImages.checkbox.square.active
                          : globalImages.checkbox.square.inactive
                      }
                      alt=""
                    />
                  </button>
                  <div className="flex flex-col">
                    <div className="text-[14px] font-medium leading-short tracking-tight text-gray-90 mb-[8px]">
                      <FormattedMessage id="cookie.detail-2.title" />
                    </div>
                    <div className="text-[12px] font-light leading-short tracking-tight text-gray-70">
                      <FormattedMessage id="cookie.detail-2.text" />
                    </div>
                  </div>
                </div>
                <div className="flex items-start">
                  <button
                    onClick={() =>
                      setAcceptPerformanceCookies(!acceptPerformanceCookies)
                    }
                    className="min-w-[20px] h-[20px] mr-[4px]"
                  >
                    <img
                      src={
                        acceptPerformanceCookies
                          ? globalImages.checkbox.square.active
                          : globalImages.checkbox.square.inactive
                      }
                      alt=""
                    />
                  </button>
                  <div className="flex flex-col">
                    <div className="text-[14px] font-medium leading-short tracking-tight text-gray-90 mb-[8px]">
                      <FormattedMessage id="cookie.detail-3.title" />
                    </div>
                    <div className="text-[12px] font-light leading-short tracking-tight text-gray-70">
                      <FormattedMessage id="cookie.detail-3.text" />
                    </div>
                  </div>
                </div>
                <div className="flex items-start">
                  <button
                    onClick={() =>
                      setAcceptTargetingCookies(!acceptTargetingCookies)
                    }
                    className="min-w-[20px] h-[20px] mr-[4px]"
                  >
                    <img
                      src={
                        acceptTargetingCookies
                          ? globalImages.checkbox.square.active
                          : globalImages.checkbox.square.inactive
                      }
                      alt=""
                    />
                  </button>
                  <div className="flex flex-col">
                    <div className="text-[14px] font-medium leading-short tracking-tight text-gray-90 mb-[8px]">
                      <FormattedMessage id="cookie.detail-4.title" />
                    </div>
                    <div className="text-[12px] font-light leading-short tracking-tight text-gray-70">
                      <FormattedMessage id="cookie.detail-4.text" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-[6px] min-h-[86px]">
                <button
                  onClick={_acceptNecessaryCookiesOnly}
                  className="h-[40px] rounded-[10px] bg-primary text-white font-light text-[14px] leading-short tracking-tight"
                >
                  <FormattedMessage id="cookie.detail.button.necessary.label" />
                </button>
                <button
                  onClick={_onConfirm}
                  className="h-[40px] text-gray-80 font-light text-[14px] leading-short tracking-tight"
                >
                  <FormattedMessage id="cookie.detail.button.confirm.label" />
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      <MobileHeader />
      <div className="w-full h-[50px] bg-beige flex items-center justify-center text-[12px] tracking-tight leading-short font-medium text-gray-80">
        <div className="w-[225px] text-center">
          <FormattedMessage id="band-banner.text" />
        </div>
      </div>
      <div className="grow flex">
        <div className="grow w-full">{children}</div>
      </div>
      <MobileFooter />
    </div>
  );
};

export { DesktopLayout, MobileLayout };
