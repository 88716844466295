import { track } from "@amplitude/analytics-browser";
import { DesktopProductBox } from "@components/boxes";
import { DesktopLayout } from "@components/layouts";

import { getBrandDetail, getBrandProducts } from "api/brands";
import { getCategories } from "api/categories";
import { assetsBaseUrl } from "common/config";
import globalImages from "common/images";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";

export const BrandDesktop = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const brandId = Number(searchParams.get("id"));

  const [spreadDetail, setSpreadDetail] = useState<boolean>(false);
  const [spreadProducts, setSpreadProducts] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);

  const [brand, setBrand] = useState<Brand>();
  const [summary, setSummary] = useState<string>("");
  const [description, setDescription] = useState<string[]>([]);
  const [categories, setCategories] = useState<GetCategoriesResponse>([]);
  const [categoryId, setCategoryId] = useState<number>(0);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    track("enter_brand", {
      brandId,
    });
  }, [brandId]);

  useEffect(() => {
    const handleScroll = () => {
      const bottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight;
      if (bottom && spreadProducts) {
        setOffset(offset + 20);
      }
    };

    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [offset, spreadProducts]);

  useEffect(() => {
    const fetch = async () => {
      const [brandData, categoriesData] = await Promise.all([
        getBrandDetail(Number(brandId)),
        getCategories(),
      ]);
      if (brandData) {
        setBrand(brandData);
        setSummary(brandData?.description.split("\n\n")[0] ?? "");
        setDescription(brandData?.description.split("\n\n").splice(1));
      }
      if (categoriesData) {
        setCategories(categoriesData);
      }
    };

    fetch();
  }, [brandId]);

  useEffect(() => {
    const fetch = async () => {
      setProducts([]);
      var productsData = [];
      if (categoryId === 0) {
        productsData = await getBrandProducts(brandId);
      } else {
        productsData = await getBrandProducts(brandId, {
          categoryId,
        });
      }
      if (productsData) {
        setProducts(productsData);
      }
    };

    // branchify
    if (true) {
      fetch();
    }
  }, [brandId, categoryId]);

  useEffect(() => {
    const fetch = async () => {
      var productsData = [];
      if (categoryId === 0) {
        productsData = await getBrandProducts(brandId, { offset });
      } else {
        productsData = await getBrandProducts(brandId, { categoryId, offset });
      }
      if (productsData.length > 0) {
        setProducts([...products, ...productsData]);
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  return (
    <DesktopLayout>
      {/* upper part */}
      <div className="pt-[72px] px-[48px] pb-[120px] flex gap-x-[84px] bg-white">
        {/* brand image */}
        <div className="bg-white rounded-[20px] flex-1 h-[550px] aspect-square flex items-center justify-center border border-gray-20">
          <img
            src={assetsBaseUrl + brand?.image}
            className="w-full rounded-[20px]"
            alt=""
          />
        </div>

        {/* brand description */}
        <div className="flex-1 py-[4px]">
          <div className="flex items-center justify-between mb-[24px] text-[28px] font-medium leading-tall tracking-tight text-gray-90">
            {(brand && brand.name) ?? ""}
          </div>

          <div className="flex flex-col gap-y-[8px] mb-[48px]">
            <div className="font-medium leading-short tracking-tight text-gray-70">
              <FormattedMessage id="brand.summary.title" />
            </div>
            <div className="font-light leading-short tracking-tight text-gray-70">
              {summary}
            </div>
          </div>

          <button
            onClick={() => {
              track("click_contact", { brandId });
              navigate({
                pathname: "/brand/contact",
                search: `?id=${brandId}`,
              });
            }}
            className="px-[80px] py-[17px] rounded-[100px] bg-primary text-white font-medium tracking-tight"
          >
            <FormattedMessage id="brand.contact.button" />
          </button>

          <div className="h-[1px] bg-gray-20 my-[48px]" />

          <div className="flex flex-col gap-y-[8px]">
            <div className="font-medium leading-short tracking-tight text-gray-70">
              <FormattedMessage id="brand.detail.title" />
            </div>
            <div
              className={`font-light leading-short tracking-tight text-gray-70 mb-[24px] ${
                spreadDetail ? "" : "line-clamp-3"
              }`}
            >
              {description.map((paragraph, index) => (
                <div key={`paragraph-${index}`}>
                  {index > 0 && <br />}
                  {paragraph.split("\n").map((line, index) => (
                    <div key={`line-${index}`}>{line}</div>
                  ))}
                </div>
              ))}
            </div>
            <button onClick={() => setSpreadDetail(!spreadDetail)}>
              <img
                src={
                  spreadDetail
                    ? globalImages.chevron.up
                    : globalImages.chevron.down
                }
                className="w-[24px] h-[24px] mx-auto"
                alt=""
              />
            </button>
          </div>
        </div>
      </div>

      {/* lower part */}
      <div className="bg-white px-[48px] pb-[120px]">
        <div className="text-center text-[32px] font-bold leading-tall tracking-tight mb-[36px] text-gray-90">
          <FormattedMessage id="brand.products.title" />
        </div>
        {/* category */}
        <div className="mb-[36px]">
          <div className="flex flex-row items-center justify-center gap-x-[8px] font-medium leading-short tracking-tight">
            <button
              className={`px-[16px] py-[8px] rounded-[100px] border ${
                categoryId === 0
                  ? "border-gray-80 text-gray-80"
                  : "border-white text-gray-20"
              }`}
              onClick={() => {
                if (categoryId !== 0) {
                  setCategoryId(0);
                }
              }}
            >
              <FormattedMessage id="collection.label.all" />
            </button>
            {categories &&
              categories.map((category, index) => (
                <button
                  key={index}
                  className={`px-[16px] py-[8px] rounded-[100px] border ${
                    category.id === categoryId
                      ? "border-gray-80 text-gray-80"
                      : "border-white text-gray-20"
                  }`}
                  onClick={() => {
                    if (category.id !== categoryId) {
                      setCategoryId(category.id);
                    }
                  }}
                >
                  {category.name}
                </button>
              ))}
          </div>
        </div>

        {/* brands */}
        <div className="grid grid-cols-4 gap-x-[16px] gap-y-[48px] mb-[72px]">
          {(spreadProducts ? products : products.slice(0, 8)).map(
            (product, index) => (
              <DesktopProductBox product={product} key={index} />
            )
          )}
        </div>

        <div className="flex">
          <button
            className="px-[80px] py-[17px] rounded-[100px] border border-gray-90 mx-auto font-light tracking-tight leading-short text-gray-90"
            onClick={() => setSpreadProducts(!spreadProducts)}
          >
            <FormattedMessage
              id={
                spreadProducts
                  ? "collection.button.fold"
                  : "collection.button.spread"
              }
            />
          </button>
        </div>
      </div>
    </DesktopLayout>
  );
};
