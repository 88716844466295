import globalImages from "common/images";

export const DesktopStars = ({ rating }: { rating: number }) => {
  const int = Math.floor(rating);
  const float = rating - int;

  return (
    <div className="h-[20px] flex items-center">
      {[...Array(int)].map((int, index) => (
        <img
          src={globalImages.icon.star.full}
          className="w-[20px] h-[20px]"
          key={index}
          alt=""
        />
      ))}
      {float >= 0.2 && (
        <img
          src={globalImages.icon.star.half}
          className="w-[20px] h-[20px]"
          alt=""
        />
      )}
    </div>
  );
};

export const MobileStars = ({ rating }: { rating: number }) => {
  const int = Math.floor(rating);
  const float = rating - int;

  return (
    <div className="h-[16px] flex items-center">
      {[...Array(int)].map((int, index) => (
        <img
          src={globalImages.icon.star.full}
          className="w-[16px] h-[16px]"
          key={index}
          alt=""
        />
      ))}
      {float >= 0.2 && (
        <img
          src={globalImages.icon.star.half}
          className="w-[16px] h-[16px]"
          alt=""
        />
      )}
    </div>
  );
};
