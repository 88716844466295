import { globalContext } from "contexts/global";
import React, { useContext } from "react";

interface Props {
  children?: React.ReactNode;
}

export const Desktop = ({ children }: Props) => {
  const { isDesktop } = useContext(globalContext);
  return <React.Fragment>{isDesktop && children}</React.Fragment>;
};
export const Mobile = ({ children }: Props) => {
  const { isMobile } = useContext(globalContext);
  return <React.Fragment>{isMobile && children}</React.Fragment>;
};
