import { track } from "@amplitude/analytics-browser";
import { MobileBrandBox, MobileProductBox } from "@components/boxes";
import { MobileLayout } from "@components/layouts";

import { getBrands } from "api/brands";
import { getCategories } from "api/categories";
import { getProducts } from "api/products";
import { assetsBaseUrl } from "common/config";
import globalImages from "common/images";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

export const CollectionMobile = () => {
  const navigate = useNavigate();
  const width = window.innerWidth;

  const [select, setSelect] = useState<"products" | "brands">("products");

  const [popularBrands, setPopularBrands] = useState<GetBrandsResponse>([]);
  const [brands, setBrands] = useState<GetBrandsResponse>([]);

  const [products, setProducts] = useState<GetProductsResponse>([]);

  const [categories, setCategories] = useState<GetCategoriesResponse>([]);
  const [categoryId, setCategoryId] = useState<number>(0);
  const [spread, setSpread] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(10);

  useEffect(() => {
    track("enter_collection");
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const bottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight;
      if (bottom && spread) {
        setOffset(offset + 20);
      }
    };

    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [offset, spread, select]);

  useEffect(() => {
    const fetch = async () => {
      const [categoriesData, popularBrandsData] = await Promise.all([
        getCategories(),
        getBrands({ sort: "amazon" }),
      ]);
      if (categoriesData) {
        setCategories(categoriesData);
      }
      if (popularBrandsData) {
        setPopularBrands(popularBrandsData);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      setSpread(false);
      setOffset(0);

      if (select === "products") {
        setProducts([]);
        var productsData = [];
        if (categoryId === 0) {
          productsData = await getProducts();
        } else {
          productsData = await getProducts({ categoryId });
        }
        if (productsData.length > 0) {
          setProducts(productsData);
        }
      } else if (select === "brands") {
        setBrands([]);
        var brandsData = [];
        if (categoryId === 0) {
          brandsData = await getBrands({ sort: "hwahae" });
        } else {
          brandsData = await getBrands({ sort: "hwahae", categoryId });
        }
        if (brandsData.length > 0) {
          setBrands(brandsData);
        }
      }
    };

    // branchify
    if (true) {
      fetch();
    }
  }, [categoryId, select]);

  useEffect(() => {
    const fetch = async () => {
      if (select === "products") {
        var productsData = [];
        if (categoryId === 0) {
          productsData = await getProducts({ offset });
        } else {
          productsData = await getProducts({ categoryId, offset });
        }
        if (productsData.length > 0) {
          setProducts([...products, ...productsData]);
        }
      } else if (select === "brands") {
        var brandsData = [];
        // set category id
        if (categoryId === 0) {
          brandsData = await getBrands({ sort: "hwahae", offset });
        } else {
          brandsData = await getBrands({ sort: "hwahae", categoryId, offset });
        }
        if (brandsData.length > 0) {
          setBrands([...brands, ...brandsData]);
        }
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  return (
    <MobileLayout>
      {/* popular brands */}
      <div className="pt-[32px] pb-[72px]">
        <div className="flex flex-col gap-y-[8px] text-center mb-[32px]">
          <div className="text-[20px] font-bold leading-tall tracking-tight text-gray-90">
            <FormattedMessage id="collection.title" />
          </div>
          <div className="text-[12px] font-light leading-short tracking-tight text-gray-70">
            <FormattedMessage id="collection.description" />
          </div>
        </div>
        <div
          className="flex gap-x-[16px] overflow-x-auto px-[16px]"
          style={{ maxWidth: width }}
        >
          {popularBrands.map((brand, index) => (
            <button
              onClick={() => {
                track("click_popular_brand", {
                  brandId: brand.id,
                });
                navigate({
                  pathname: "/brand",
                  search: `?id=${brand.id}`,
                });
              }}
              key={index}
              className="flex flex-col items-center"
            >
              <div className="w-[120px] h-[120px] bg-gray-10 rounded-full flex items-center justify-center mb-[16px] border border-gray-10">
                <img
                  src={assetsBaseUrl + brand.image}
                  className="rounded-full"
                  alt=""
                />
              </div>
              <div className="text-[12px] font-medium tracking-tighter text-gray-90 leading-short max-w-[120px] line-clamp-2">
                {brand.name}
              </div>
            </button>
          ))}
        </div>
      </div>

      {/* benefits */}
      <div className="flex flex-col justify-center items-end px-[16px] h-[82px] mb-[120px] bg-[url('./assets/images/product-points-background-mobile.png')] bg-cover">
        <div className="flex flex-col gap-y-[8px]">
          <div className="flex items-center">
            <img
              src={globalImages.icon.check.blue}
              className="w-[14px] h-[14px] mr-[8px]"
              alt=""
            />
            <span className="text-[12px] font-medium leading-short tracking-tight text-gray-90">
              <FormattedMessage id="collection.benefit-1.title" />
            </span>
          </div>
          <div className="flex items-center">
            <img
              src={globalImages.icon.check.blue}
              className="w-[14px] h-[14px] mr-[8px]"
              alt=""
            />
            <span className="text-[12px] font-medium leading-short tracking-tight text-gray-90">
              <FormattedMessage id="collection.benefit-2.title" />
            </span>
          </div>
        </div>
      </div>

      {/* categories */}
      <div className="mb-[24px]">
        <div className="flex items-start justify-center gap-x-[16px] text-[18px] font-medium leading-short tracking-tight mb-[24px]">
          <button
            className={`${
              select === "products" ? "text-primary" : "text-gray-20"
            }`}
            onClick={() => {
              if (select !== "products") {
                setSelect("products");
              }
            }}
          >
            <FormattedMessage id="collection.label.products" />
            {select === "products" && <div className="h-[2px] bg-primary" />}
          </button>
          <button
            className={`${
              select === "brands" ? "text-primary" : "text-gray-20"
            }`}
            onClick={() => {
              if (select !== "brands") {
                setSelect("brands");
              }
            }}
          >
            <FormattedMessage id="collection.label.brands" />
            {select === "brands" && <div className="h-[2px] bg-primary" />}
          </button>
        </div>
        <div
          className="flex flex-row items-center gap-x-[4px] font-medium leading-short tracking-tight overflow-x-auto px-[16px] whitespace-nowrap"
          style={{
            maxWidth: width,
          }}
        >
          <button
            className={`px-[12px] py-[6px] rounded-[100px] border ${
              categoryId === 0
                ? "border-gray-80 text-gray-80"
                : "border-white text-gray-20"
            }`}
            onClick={() => {
              if (categoryId !== 0) {
                setCategoryId(0);
              }
            }}
          >
            <FormattedMessage id="collection.label.all" />
          </button>
          {categories.map((category, index) => (
            <button
              key={index}
              className={`px-[12px] py-[6px] rounded-[100px] border ${
                category.id === categoryId
                  ? "border-gray-80 text-gray-80"
                  : "border-white text-gray-20"
              }`}
              onClick={() => {
                if (category.id !== categoryId) {
                  setCategoryId(category.id);
                }
              }}
            >
              {category.name}
            </button>
          ))}
        </div>
      </div>

      {/* products & brands */}
      {select === "products" ? (
        <div className=" px-[16px] w-full grid grid-cols-2 gap-x-[8px] gap-y-[32px] mb-[48px]">
          {(spread ? products : products.slice(0, 4)).map((product, index) => (
            <MobileProductBox product={product} key={index} />
          ))}
        </div>
      ) : (
        <div className="px-[16px] w-full grid grid-cols-2 gap-x-[8px] gap-y-[32px] mb-[48px]">
          {(spread ? brands : brands.slice(0, 4)).map((brand, index) => (
            <MobileBrandBox brand={brand} key={index} />
          ))}
        </div>
      )}

      {/* more button */}
      <div className="flex mb-[100px]">
        <button
          className="px-[48px] py-[14px] rounded-[100px] border border-gray-90 mx-auto font-light tracking-tight leading-short text-gray-90 text-[13px]"
          onClick={() => setSpread(!spread)}
        >
          <FormattedMessage
            id={spread ? "collection.button.fold" : "collection.button.spread"}
          />
        </button>
      </div>
    </MobileLayout>
  );
};
