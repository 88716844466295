import { track } from "@amplitude/analytics-browser";
import { DesktopBrandBox, DesktopProductBox } from "@components/boxes";
import { DesktopLayout } from "@components/layouts";

import { getBrands } from "api/brands";
import { getCategories } from "api/categories";
import { getProducts } from "api/products";
import { CAROUSEL_CONFIG, assetsBaseUrl } from "common/config";
import globalImages from "common/images";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

export const CollectionDesktop = () => {
  const navigate = useNavigate();

  const [select, setSelect] = useState<"products" | "brands">("products");

  const [popularBrands, setPopularBrands] = useState<GetBrandsResponse>([]);
  const [brands, setBrands] = useState<GetBrandsResponse>([]);

  const [products, setProducts] = useState<GetProductsResponse>([]);

  const [categories, setCategories] = useState<GetCategoriesResponse>([]);
  const [categoryId, setCategoryId] = useState<number>(0);
  const [spread, setSpread] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);

  const slider = useRef<any>(null);

  useEffect(() => {
    track("enter_collection");
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const bottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight;
      if (bottom && spread) {
        setOffset(offset + 20);
      }
    };

    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [offset, spread, select]);

  useEffect(() => {
    const fetch = async () => {
      const [categoriesData, popularBrandsData] = await Promise.all([
        getCategories(),
        getBrands({ sort: "amazon" }),
      ]);
      if (categoriesData) {
        setCategories(categoriesData);
      }
      if (popularBrandsData) {
        setPopularBrands(popularBrandsData);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      setSpread(false);
      setOffset(0);

      if (select === "products") {
        setProducts([]);
        var productsData = [];
        if (categoryId === 0) {
          productsData = await getProducts();
        } else {
          productsData = await getProducts({ categoryId });
        }
        if (productsData.length > 0) {
          setProducts(productsData);
        }
      } else if (select === "brands") {
        setBrands([]);
        var brandsData = [];
        if (categoryId === 0) {
          brandsData = await getBrands({ sort: "hwahae" });
        } else {
          brandsData = await getBrands({ sort: "hwahae", categoryId });
        }
        if (brandsData.length > 0) {
          console.log(brandsData.length);
          setBrands(brandsData);
        }
      }
    };

    // branchify
    if (true) {
      fetch();
    }
  }, [categoryId, select]);

  useEffect(() => {
    const fetch = async () => {
      if (select === "products") {
        var productsData = [];
        if (categoryId === 0) {
          productsData = await getProducts({ offset });
        } else {
          productsData = await getProducts({ categoryId, offset });
        }
        if (productsData.length > 0) {
          setProducts([...products, ...productsData]);
        }
      } else if (select === "brands") {
        var brandsData = [];
        // set category id
        if (categoryId === 0) {
          brandsData = await getBrands({ sort: "hwahae", offset });
        } else {
          brandsData = await getBrands({ sort: "hwahae", categoryId, offset });
        }
        if (brandsData.length > 0) {
          setBrands([...brands, ...brandsData]);
        }
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  return (
    <DesktopLayout>
      <div className="w-[1280px] px-[48px] pt-[70px] pb-[120px] bg-white">
        {/* Popular brands */}
        <div className="mb-[120px]">
          <div className="flex items-center gap-x-[12px] mb-[36px]">
            <span className="text-[32px] font-bold leading-tall tracking-tight text-gray-90">
              <FormattedMessage id="collection.title" />
            </span>
            <span className="font-light leading-short tracking-tight text-gray-70">
              <FormattedMessage id="collection.description" />
            </span>
          </div>
          <div className="flex justify-between items-center">
            <button
              onClick={() => slider?.current?.slickPrev()}
              className="w-[48px] h-[48px] border rounded-full flex items-center justify-center border-gray-20 hover:border-gray-90"
            >
              <img
                src={globalImages.chevron.left}
                className="w-[30px]"
                alt=""
              />
            </button>
            <Slider
              ref={slider}
              {...CAROUSEL_CONFIG.collection}
              className="max-w-[1028px] grow gap-x-[16px]"
            >
              {popularBrands.map((brand, index) => (
                <button
                  onClick={() => {
                    track("click_popular_brand", {
                      brandId: brand.id,
                    });
                    navigate({
                      pathname: "/brand",
                      search: `?id=${brand.id}`,
                    });
                  }}
                  key={index}
                  className="flex flex-col items-center"
                >
                  <div className="w-[180px] h-[180px] bg-gray-10 rounded-full flex items-center justify-center mb-[16px] border border-gray-10">
                    <img
                      src={assetsBaseUrl + brand.image}
                      className="w-[180px] rounded-full"
                      alt=""
                    />
                  </div>
                  <div className="text-[14px] font-medium tracking-tighter text-gray-90 leading-short">
                    {brand.name}
                  </div>
                </button>
              ))}
            </Slider>
            <button
              onClick={() => slider?.current?.slickNext()}
              className="w-[48px] h-[48px] border rounded-full flex items-center justify-center border-gray-20 hover:border-gray-90"
            >
              <img
                src={globalImages.chevron.right}
                className="w-[30px]"
                alt=""
              />
            </button>
          </div>
        </div>

        {/* benefits */}
        <div className="flex flex-col justify-center items-end px-[130px] h-[180px] rounded-[20px] mb-[120px] bg-[url('./assets/images/product-points-background.png')] bg-cover">
          <div>
            <div className="flex items-center">
              <img
                src={globalImages.icon.check.blue}
                className="w-[24px] h-[24px] mr-[12px]"
                alt=""
              />
              <span className="text-[28px] font-medium leading-tall tracking-tight text-gray-90">
                <FormattedMessage id="collection.benefit-1.title" />
              </span>
            </div>
            <div className="flex items-center">
              <img
                src={globalImages.icon.check.blue}
                className="w-[24px] h-[24px] mr-[12px]"
                alt=""
              />
              <span className="text-[28px] font-medium leading-tall tracking-tight text-gray-90">
                <FormattedMessage id="collection.benefit-2.title" />
              </span>
            </div>
          </div>
        </div>

        {/* categories */}
        <div className="mb-[36px]">
          <div className="flex items-start justify-center gap-x-[32px] text-[32px] font-medium leading-tall tracking-tight mb-[24px]">
            <button
              className={`${
                select === "products" ? "text-primary" : "text-gray-20"
              }`}
              onClick={() => {
                if (select !== "products") {
                  setSelect("products");
                }
              }}
            >
              <FormattedMessage id="collection.label.products" />
              {select === "products" && <div className="h-[2px] bg-primary" />}
            </button>
            <button
              className={`${
                select === "brands" ? "text-primary" : "text-gray-20"
              }`}
              onClick={() => {
                if (select !== "brands") {
                  setSelect("brands");
                }
              }}
            >
              <FormattedMessage id="collection.label.brands" />
              {select === "brands" && <div className="h-[2px] bg-primary" />}
            </button>
          </div>
          <div className="flex flex-row items-center justify-center gap-x-[8px] font-medium leading-short tracking-tight whitespace-nowrap">
            <button
              className={`px-[16px] py-[8px] rounded-[100px] border ${
                categoryId === 0
                  ? "border-gray-80 text-gray-80"
                  : "border-white text-gray-20"
              }`}
              onClick={() => {
                if (categoryId !== 0) {
                  setCategoryId(0);
                }
              }}
            >
              <FormattedMessage id="collection.label.all" />
            </button>
            {categories &&
              categories.map((category, index) => (
                <button
                  key={index}
                  className={`px-[16px] py-[8px] rounded-[100px] border ${
                    category.id === categoryId
                      ? "border-gray-80 text-gray-80"
                      : "border-white text-gray-20"
                  }`}
                  onClick={() => {
                    if (category.id !== categoryId) {
                      setCategoryId(category.id);
                    }
                  }}
                >
                  {category.name}
                </button>
              ))}
          </div>
        </div>

        {/* products & brands */}
        {select === "products" ? (
          <div className="w-full grid grid-cols-4 gap-x-[16px] gap-y-[48px] mb-[72px]">
            {(spread ? products : products.slice(0, 8)).map(
              (product, index) => (
                <DesktopProductBox product={product} key={index} />
              )
            )}
          </div>
        ) : (
          <div className="w-full grid grid-cols-4 gap-x-[16px] gap-y-[48px] mb-[72px]">
            {(spread ? brands : brands.slice(0, 8)).map((brand, index) => (
              <DesktopBrandBox brand={brand} key={index} />
            ))}
          </div>
        )}

        <div className="flex">
          <button
            className="px-[80px] py-[17px] rounded-[100px] border border-gray-90 mx-auto font-light tracking-tight leading-short text-gray-90"
            onClick={() => setSpread(!spread)}
          >
            <FormattedMessage
              id={
                spread ? "collection.button.fold" : "collection.button.spread"
              }
            />
          </button>
        </div>
      </div>
    </DesktopLayout>
  );
};
