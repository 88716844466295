import { track } from "@amplitude/analytics-browser";
import { MobileLayout } from "@components/layouts";
import { MobileStars } from "@components/stars";
import { CAROUSEL_CONFIG } from "common/config";
import { CURATION_PAGE_DATA } from "common/data";
import globalImages from "common/images";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

export const CurationMobile = () => {
  const navigate = useNavigate();

  return (
    <MobileLayout>
      <div className="bg-ivory w-screen px-[16px] pt-[32px] pb-[80px]">
        {/* banner */}
        <div className="bg-[url('./assets/images/curation-banner-background.png')] bg-cover bg-center w-full h-[356px] flex flex-col items-center justify-center text-center rounded-[20px] mb-[80px]">
          <div className="flex flex-col text-[28px] w-[230px] font-bold tracking-tight leading-tall mb-[16px] text-white">
            <FormattedMessage id="curation.banner.title" />
          </div>
          <div className="flex flex-col text-[16px] font-light tracking-tight leading-short text-white mb-[40px] w-[300px]">
            <FormattedMessage id="curation.banner.text" />
          </div>
          <button className="bg-white px-[16px] py-[8px] rounded-[200px] flex gap-x-[4px] items-center">
            <div className="text-[14px] leading-short tracking-tight text-gray-90">
              <FormattedMessage id="curation.banner.cta" />
            </div>
            <img
              src={globalImages.arrow.circle.right}
              className="w-[12px] h-[12px]"
              alt=""
            />
          </button>
        </div>

        {/* emerging products */}
        <div className="mb-[80px]">
          <div className="text-center text-[20px] text-gray-90 font-medium leading-tall tracking-tight mb-[36px]">
            <FormattedMessage id="curation.emerging.title" />
          </div>
          <Slider {...CAROUSEL_CONFIG.curation.mobile}>
            {CURATION_PAGE_DATA.emergingProducts.map((product, index) => (
              <div className="rounded-[20px]" key={index}>
                <div>
                  <img
                    src={product.image}
                    className="max-h-[180px] w-full object-cover rounded-t-[20px]"
                    alt=""
                  />
                  <button
                    onClick={() => {
                      if (product.productId) {
                        track(`curation-emerging-${index + 1}`);
                        navigate({
                          pathname: "/product",
                          search: `?id=${product.productId}`,
                        });
                      }
                    }}
                    className="text-left bg-white flex flex-col px-[16px] py-[24px] rounded-b-[20px] min-h-[280px]"
                  >
                    <div className="w-full mb-[12px] flex items-center justify-between">
                      <span className="text-[12px] text-gray-70 leading-tall tracking-tight font-light">
                        {product.brand}
                      </span>
                      <img
                        src={globalImages.icon.arrowRight}
                        className="w-[16px] h-[16px]"
                        alt=""
                      />
                    </div>
                    <div className="text-[16px] text-gray-90 font-medium tracking-tight leading-short mb-[8px]">
                      {product.title}
                    </div>
                    <div className="flex items-center mb-[20px]">
                      <MobileStars rating={product.rating} />
                      <span className="text-[12px] text-gray-70 font-medium leading-short tracking-tight">
                        {product.rating}
                      </span>
                    </div>

                    <div className="text-[12px] font-light tracking-tight leading-short text-gray-70">
                      {product.description
                        ?.split("\n\n")
                        .map((paragraph, index) => (
                          <div key={`paragraph-${index}`}>
                            {index > 0 && <br />}
                            {paragraph.split("\n").map((line, index) => (
                              <div key={`line-${index}`}>{line}</div>
                            ))}
                          </div>
                        )) ?? ""}
                    </div>
                  </button>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* influencers' pick */}
        <div className="mb-[80px]">
          <div className="text-center text-[20px] text-gray-90 font-medium leading-tall tracking-tight mb-[8px]">
            <FormattedMessage id="curation.pick.title" />
          </div>
          <div className="text-center text-[12px] text-gray-70 font-light leading-short tracking-tight mb-[36px]">
            <FormattedMessage id="curation.pick.subtitle" />
          </div>
          <div className="grid grid-cols-2 gap-x-[8px] gap-y-[24px]">
            {CURATION_PAGE_DATA.influencersPick.map((product, index) => (
              <button key={index}>
                <div className="flex flex-col text-left">
                  <img src={product.image} className="mb-[12px]" alt="" />
                  <div className="px-[4px] flex flex-col gap-y-[4px]">
                    <div className="text-[12px] font-light text-gray-70 leading-short tracking-tight">
                      {product.brand}
                    </div>
                    <div className="text-[14px] font-light text-gray-90 leading-short tracking-tight">
                      {product.title}
                    </div>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>

        {/* top selling brand */}
        <div className="mb-[80px]">
          <div className="text-center text-[20px] text-gray-90 font-medium leading-tall tracking-tight mb-[36px] flex justify-center">
            <div className="w-[280px]">
              <FormattedMessage id="curation.top.title" />
            </div>
          </div>
          <div className="flex flex-col gap-y-[36px]">
            {CURATION_PAGE_DATA.monthlyTop.map((brand, index) => (
              <div className="flex flex-col" key={index}>
                <div>
                  <div className="text-[12px] text-primary font-light leading-short tracking-tight mb-[12px]">
                    <FormattedMessage id="curation.top.prefix" /> {index + 1}
                  </div>
                  <div className="flex items-center justify-between mb-[24px]">
                    <img src={brand.logo} className="h-[24px]" alt="" />
                    <button
                      onClick={() => {
                        if (brand.brandId) {
                          track(`curation-brand-top-${index + 1}`);
                          navigate({
                            pathname: "/brand",
                            search: `?id=${brand.brandId}`,
                          });
                        }
                      }}
                      className="px-[10px] py-[4px] bg-white rounded-[100px] flex items-center gap-x-[4px]"
                    >
                      <div className="text-[12px] text-gray-90 font-medium leading-short tracking-tight">
                        <FormattedMessage id="curation.top.button.mobile" />
                      </div>
                      <img
                        src={globalImages.arrow.circle.right}
                        className="w-[12px] h-[12px]"
                        alt=""
                      />
                    </button>
                  </div>
                </div>

                <div className="flex gap-x-[8px] overflow-x-auto">
                  {brand.products.map((product, index) => (
                    <button
                      onClick={() => {
                        if (product.productId) {
                          navigate({
                            pathname: "/product",
                            search: `?id=${product.productId}`,
                          });
                        }
                      }}
                      key={index}
                      className="min-w-[160px] text-left"
                    >
                      <img src={product.image} className="mb-[12px]" alt="" />
                      <div className="text-[14px] text-gray-90 font-light leading-short tracking-tight px-[4px] min-h-[40px]">
                        {product.title}
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* md's pick */}
        <div className="mb-[80px]">
          <div className="text-center text-[20px] text-gray-90 font-medium leading-tall tracking-tight mb-[36px]">
            <FormattedMessage id="curation.md.title" />
          </div>
          <div className="flex gap-x-[8px] overflow-x-auto">
            {CURATION_PAGE_DATA.mdsPick.map((product, index) => (
              <button
                onClick={() => {
                  if (product.productId) {
                    track(`curation-md-${index + 1}`);
                    navigate({
                      pathname: "/product",
                      search: `?id=${product.productId}`,
                    });
                  }
                }}
                key={index}
                className="min-w-[160px] rounded-[20px] pt-[26px] pb-[24px] px-[16px] bg-white flex flex-col items-center text-left"
              >
                <img src={product.image} className="mb-[8px]" alt="" />
                <div className="flex flex-col gap-y-[4px]">
                  <div className="text-[12px] text-gray-70 font-light leading-short tracking-tight">
                    {product.brand}
                  </div>
                  <div className="text-[12px] text-gray-90 font-light leading-short tracking-tight max-w-[230px] min-h-[44px]">
                    {product.title}
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>

        {/* cta */}
        <div className="sticky bottom-[48px] flex justify-center">
          <button
            onClick={() => navigate("/collection")}
            className="h-[56px] bg-primary rounded-[100px] px-[24px] py-[16px]"
          >
            <div className="text-[16px] text-white leading-short tracking-tight">
              <FormattedMessage id="curation.cta" />
            </div>
          </button>
        </div>
      </div>
    </MobileLayout>
  );
};
