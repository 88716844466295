export const LANGUAGE_OPTIONS = [
  {
    id: 1,
    locale: "en",
    label: "English",
  },
  {
    id: 2,
    locale: "ja",
    label: "日本語",
  },
  {
    id: 3,
    locale: "vi",
    label: "Tiếng Việt",
  },
  {
    id: 4,
    locale: "zh-tw",
    label: "臺語",
  },
  {
    id: 5,
    locale: "th",
    label: "ไทย",
  },
  {
    id: 6,
    locale: "ru",
    label: "Русский",
  },
  // {
  //   id: 7,
  //   locale: "ko",
  //   label: "한국어",
  // },
];

export const CAROUSEL_CONFIG = {
  collection: {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  },
  curation: {
    desktop: {
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
    mobile: {
      arrows: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
};

export const assetsBaseUrl = "https://assets.cosmarie.com/";

export const SENTRY_DSN =
  "https://742ee5d368626133e2a0b51a62470b6b@o623955.ingest.sentry.io/4506539630526464";

export const CHANNEL_IO_CONFIG = {
  pluginKey: "6078e733-1796-43ea-86ac-2d02b94e523a",
  hideChannelButtonOnBoot: false,
  // Tony Kim : To make CS easy, language en option is fixed.
  language: "en",
};

// trackers
export const AMPLITUDE_API_KEY = "af70dda7c6e366afa301f3ae9e673f5e";
export const HOTJAR_SITE_ID = 3810509;
export const HOTJAR_VERSION = 6;

export const PIXEL_ID = "250070420947707";
