export const TERMS_OF_SERVICE_LINK =
  "https://postalpine.notion.site/COSMARIE-TERMS-OF-SERVICE-b96c795fc5d34848ad956ab6f98c99d1?pvs=4";
export const PRIVACY_POLICY_LINK =
  "https://postalpine.notion.site/PRIVACY-POLICY-f9385688c295425e8d3763bc7dcf3bf8?pvs=4";
export const COOKIE_POLICY_LINK =
  "https://postalpine.notion.site/COOKIE-POLICY-6de1aa6996644e9ba992ba01d8f92684?pvs=4";

export const CATALOG_DOWNLOAD_LINK =
  "https://drive.google.com/file/d/10cBRMfb2r8icx1atTSjUz3xoE0dMGBwe/view?usp=sharing";

export const CURATION_CTA_LINK = "https://ll8e4.channel.io/home";

export const BUSINESS_TYPE_LIST = [
  "Brick and mortar",
  "Pop-up shop",
  "Online only",
  "other",
];

export const ANNUAL_SALES_LIST = [
  "$0 - $50K",
  "$50K - $100K",
  "$100K - $200K",
  "$200K - $500K",
  "$500K - $1M",
  ">$1M",
];

export const CURATION_PAGE_DATA = {
  emergingProducts: [
    {
      brand: "The Lab by Blanc Doux",
      title: "Oligo Hyaluronic Acid Deep Toner",
      rating: 4.7,
      description:
        "Introducing THE LAB by BLANC DOUX Oligo Hyaluronic Acid Deep Toner - your ultimate solution to deep skin hydration.\n\nHyal Origin™: A breakthrough in hydrating technology, this potent seed of moisture is finely cut to 0.2 kDa, ensuring unprecedented absorption and moisture delivery deep within the skin layers.",
      image: require("@images/curation-emerging-1.png"),
      productId: 1,
    },
    {
      brand: "About Me",
      title: "SOOP Calming Aqua Cream",
      rating: 4.6,
      description:
        "Embrace the Tranquility of Nature for Your Skin with About Me SOOP Calming Aqua Cream\n\nDiscover your skin's oasis with the About Me SOOP Calming Aqua Cream, a hydrating haven specially formulated for troubled skin.",
      image: require("@images/curation-emerging-2.png"),
      productId: 7,
    },
    {
      brand: "Freemay",
      title: "Olive Cica Calming Ampoule Mask",
      rating: 4.7,
      description:
        "Discover the oasis of hydration with Freemay Olive Cica Calming Ampoule Mask, a vegan-certified soothing wonder designed to cater to your skin's needs for moisture, elasticity, and healing. Our bamboo-derived biodegradable sheet pairs with a potent elixir of Centella Asiatica, known for its scar-healing and anti-aging properties, to fortify and rejuvenate tired skin.",
      image: require("@images/curation-emerging-3.png"),
      productId: 1585,
    },
    {
      brand: "Wonjin Effect",
      title: "Melaless Cream",
      rating: 4.6,
      description:
        "Discover the power of the MELALESS Toning Cream, a breakthrough in the fight against stubborn dark spots and uneven skin tone. With over 80,000 units sold, this top-rated formula has been proven to enhance skin clarity and radiance in just two weeks.",
      image: require("@images/curation-emerging-4.png"),
      productId: 8,
    },
  ],
  influencersPick: [
    {
      brand: "WELLAGE",
      title: "Real Hyaluronic Blue 100 Ampoule",
      image: require("@images/curation-pick-1.png"),
      productId: 16,
    },
    {
      brand: "S.NATURE",
      title: "Aqua Oasis Toner",
      image: require("@images/curation-pick-2.png"),
      productId: 11,
    },
    {
      brand: "S.NATURE",
      title: "Aqua Squalane Moisturizing Cream",
      image: require("@images/curation-pick-3.png"),
      productId: 10,
    },
    {
      brand: "Dr.G",
      title: "Red Blemish Clear Soothing Cream",
      image: require("@images/curation-pick-4.png"),
      productId: 14,
    },
  ],
  monthlyTop: [
    {
      logo: require("@images/curation-top1.png"),
      brandId: 104,
      products: [
        {
          title: "Divein Low Molecular Hyaluronic Acid Serum",
          productId: 9,
          image: require("@images/curation-top1-1.png"),
        },
        {
          title: "Divein Low Molecular Hyaluronic Acid Mask",
          productId: 1587,
          image: require("@images/curation-top1-2.png"),
        },
        {
          title: "Divein Low Molecular Hyaluronic Acid cleanser",
          productId: 1228,
          image: require("@images/curation-top1-3.png"),
        },
        {
          title: "Divein Low Molecular Hyaluronic Acid Toner",
          productId: 13,
          image: require("@images/curation-top1-4.png"),
        },
      ],
    },
    {
      logo: require("@images/curation-top2.png"),
      brandId: 116,
      products: [
        {
          title: "1025 Dokdo Toner",
          productId: 15,
          image: require("@images/curation-top2-1.png"),
        },
        {
          title: "Birch Moisture Sunblock",
          productId: 1917,
          image: require("@images/curation-top2-2.png"),
        },
        {
          title: "1025 Dokdo Lotion",
          productId: 31,
          image: require("@images/curation-top2-3.png"),
        },
        {
          title: "1025 Dokdo Cleanser",
          productId: 1250,
          image: require("@images/curation-top2-4.png"),
        },
      ],
    },
    {
      logo: require("@images/curation-top3.png"),
      brandId: 7,
      products: [
        {
          title: "pure cleansing oil",
          productId: 1231,
          image: require("@images/curation-top3-1.png"),
        },
        {
          title: "Bifida Biome Ampoule Toner",
          productId: 72,
          image: require("@images/curation-top3-2.png"),
        },
        {
          title: "Heather Calming Essence Pad",
          productId: null,
          image: require("@images/curation-top3-3.png"),
        },
        {
          title: "pantetoin cream",
          productId: 320,
          image: require("@images/curation-top3-4.png"),
        },
      ],
    },
  ],
  mdsPick: [
    {
      image: require("@images/md-pick-1.png"),
      brand: "AMUSE",
      title: "Amuse Dew Tint - 04 Carrot Dew",
      productId: 2672,
    },
    {
      image: require("@images/md-pick-2.png"),
      brand: "UIQ",
      title: "Biome Barrier Moisture Melting Lip Balm",
      productId: 2682,
    },
    {
      image: require("@images/md-pick-3.png"),
      brand: "SIDMOOL",
      title: "Jojoba Lip Essence Raw Material Fragrance",
      productId: 2674,
    },
    {
      image: require("@images/md-pick-4.png"),
      brand: "MA:NYO",
      title: "No Mercy Spell Mood Stick - Meroz Elysee",
      productId: null,
    },
  ],
};
