import { init, reset } from "@amplitude/analytics-browser";
import Hotjar from "@hotjar/browser";
import en from "@lang/en.json";
import ja from "@lang/ja.json";
import ru from "@lang/ru.json";
import th from "@lang/th.json";
import vi from "@lang/vi.json";
import zhTw from "@lang/zh-tw.json";
import {
  AMPLITUDE_API_KEY,
  CHANNEL_IO_CONFIG,
  HOTJAR_SITE_ID,
  HOTJAR_VERSION,
  PIXEL_ID,
  SENTRY_DSN,
} from "common/config";
import { camelize } from "common/utils";
import { GlobalContextProvider } from "contexts/global";
import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { IntlProvider } from "react-intl";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  Brand,
  BrandContact,
  Collection,
  Curation,
  Home,
  Product,
  ProductContact,
} from "./pages";
import * as Sentry from "@sentry/react";

function App() {
  const location = useLocation();
  const locale = localStorage.getItem("locale")
    ? (localStorage.getItem("locale") as string)
    : ["en", "ja", "vi", "zh-tw", "th", "ru"].includes(navigator.language)
    ? navigator.language
    : "en";
  const messages = { en, ja, vi, zhTw, th, ru }[camelize(locale)];

  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0 });
  }, [location]);

  useEffect(() => {
    const necessaryCookies = localStorage.getItem("accept-necessary-cookies");
    const functionalCookies = localStorage.getItem("accept-functional-cookies");
    const performanceCookies = localStorage.getItem(
      "accept-performance-cookies"
    );
    const targetingCookies = localStorage.getItem("accept-targeting-cookies");

    // cookie control
    if (necessaryCookies) {
      try {
        // boot ChannelIO
        if (functionalCookies) {
          window.ChannelIO?.("boot", CHANNEL_IO_CONFIG);
        } else {
          window.ChannelIO?.("shutdown");
        }

        if (process.env.NODE_ENV === "production") {
          Sentry.init({
            dsn: SENTRY_DSN,
            integrations: [
              new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
              }),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
          });

          if (performanceCookies) {
            // init trackers
            init(AMPLITUDE_API_KEY);
            Hotjar.init(HOTJAR_SITE_ID, HOTJAR_VERSION);
          } else {
            reset();
          }

          if (targetingCookies) {
            // user targeting
            ReactPixel.init(PIXEL_ID);
            ReactPixel.pageView();
          }
        }

        return () => {
          window.ChannelIO?.("shutdown");
          reset();
        };
      } catch {}
    }
  }, []);

  return (
    <GlobalContextProvider>
      <IntlProvider locale={locale} messages={messages}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/collection" element={<Collection />} />
          <Route path="/product" element={<Product />} />
          <Route path="/brand" element={<Brand />} />
          <Route path="/product/contact" element={<ProductContact />} />
          <Route path="/brand/contact" element={<BrandContact />} />
          <Route path="/curation" element={<Curation />} />
        </Routes>
      </IntlProvider>
    </GlobalContextProvider>
  );
}

export default App;
