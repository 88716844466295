import { track } from "@amplitude/analytics-browser";
import { MobileProductBox } from "@components/boxes";
import { MobileLayout } from "@components/layouts";

import { getBrandDetail, getBrandProducts } from "api/brands";
import { getCategories } from "api/categories";
import { assetsBaseUrl } from "common/config";
import globalImages from "common/images";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";

export const BrandMobile = () => {
  const width = window.innerWidth;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const brandId = Number(searchParams.get("id"));

  const [spreadDetail, setSpreadDetail] = useState<boolean>(false);
  const [spreadProducts, setSpreadProducts] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);

  const [brand, setBrand] = useState<Brand>();
  const [summary, setSummary] = useState<string>("");
  const [description, setDescription] = useState<string[]>([]);
  const [categories, setCategories] = useState<GetCategoriesResponse>([]);
  const [categoryId, setCategoryId] = useState<number>(0);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    track("enter_brand", {
      brandId,
    });
  }, [brandId]);

  useEffect(() => {
    const handleScroll = () => {
      const bottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight;
      if (bottom && spreadProducts) {
        setOffset(offset + 20);
      }
    };

    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [offset, spreadProducts]);

  useEffect(() => {
    const fetch = async () => {
      const [brandData, categoriesData] = await Promise.all([
        getBrandDetail(Number(brandId)),
        getCategories(),
      ]);
      if (brandData) {
        setBrand(brandData);
        setSummary(brandData?.description.split("\n\n")[0] ?? "");
        setDescription(brandData?.description.split("\n\n").splice(1));
      }
      if (categoriesData) {
        setCategories(categoriesData);
      }
    };

    fetch();
  }, [brandId]);

  useEffect(() => {
    const fetch = async () => {
      setProducts([]);
      var productsData = [];
      if (categoryId === 0) {
        productsData = await getBrandProducts(brandId);
      } else {
        productsData = await getBrandProducts(brandId, {
          categoryId,
        });
      }
      if (productsData) {
        setProducts(productsData);
      }
    };

    // branchify
    if (true) {
      fetch();
    }
  }, [brandId, categoryId]);

  useEffect(() => {
    const fetch = async () => {
      var productsData = [];
      if (categoryId === 0) {
        productsData = await getBrandProducts(brandId, { offset });
      } else {
        productsData = await getBrandProducts(brandId, { categoryId, offset });
      }
      if (productsData.length > 0) {
        setProducts([...products, ...productsData]);
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  return (
    <div>
      <MobileLayout>
        {/* brand image */}
        <div>
          <img className="w-full" src={assetsBaseUrl + brand?.image} alt="" />
        </div>

        <div className="px-[16px] pt-[32px]">
          <div className="mb-[16px] text-[20px] font-medium leading-tall tracking-tight text-gray-90">
            {brand?.name}
          </div>

          {/* brand description */}
          <div className="flex flex-col">
            <div className="font-medium leading-short tracking-tight text-gray-70 mb-[8px]">
              <FormattedMessage id="brand.summary.title" />
            </div>
            <div className="text-[14px] font-light leading-short tracking-tight text-gray-70">
              {summary}
            </div>
          </div>

          <div className="h-[1px] bg-gray-10 my-[36px]" />

          {/* brand detail */}
          <div className="flex flex-col mb-[72px]">
            <div className="font-medium leading-short tracking-tight text-gray-70 mb-[8px]">
              <FormattedMessage id="brand.detail.title" />
            </div>
            <div
              className={`text-[14px] font-light leading-short tracking-tight text-gray-70 mb-[24px] ${
                spreadDetail ? "" : "line-clamp-3"
              }`}
            >
              {description.map((paragraph, index) => (
                <div key={`paragraph-${index}`}>
                  {index > 0 && <br />}
                  {paragraph.split("\n").map((line, index) => (
                    <div key={`line-${index}`}>{line}</div>
                  ))}
                </div>
              ))}
            </div>
            <button onClick={() => setSpreadDetail(!spreadDetail)}>
              <img
                src={
                  spreadDetail
                    ? globalImages.chevron.up
                    : globalImages.chevron.down
                }
                className="w-[24px] h-[24px] mx-auto"
                alt=""
              />
            </button>
          </div>
        </div>

        {/* products */}
        <div className="mb-[72px]">
          <div className="text-center text-[20px] font-bold leading-tall tracking-tight mb-[24px] text-gray-90">
            <FormattedMessage id="brand.products.title" />
          </div>

          {/* category */}
          <div
            className="flex flex-row items-center gap-x-[4px] font-medium leading-short tracking-tight overflow-x-auto px-[16px] whitespace-nowrap mb-[24px]"
            style={{
              maxWidth: width,
            }}
          >
            <button
              className={`px-[12px] py-[6px] rounded-[100px] border ${
                categoryId === 0
                  ? "border-gray-80 text-gray-80"
                  : "border-white text-gray-20"
              }`}
              onClick={() => {
                if (categoryId !== 0) {
                  setCategoryId(0);
                }
              }}
            >
              <FormattedMessage id="collection.label.all" />
            </button>
            {categories.map((category, index) => (
              <button
                key={index}
                className={`px-[12px] py-[6px] rounded-[100px] border ${
                  category.id === categoryId
                    ? "border-gray-80 text-gray-80"
                    : "border-white text-gray-20"
                }`}
                onClick={() => {
                  if (category.id !== categoryId) {
                    setCategoryId(category.id);
                  }
                }}
              >
                {category.name}
              </button>
            ))}
          </div>

          {/* brands */}
          <div className=" px-[16px] w-full grid grid-cols-2 gap-x-[8px] gap-y-[32px] mb-[48px]">
            {(spreadProducts ? products : products.slice(0, 4)).map(
              (product, index) => (
                <MobileProductBox product={product} key={index} />
              )
            )}
          </div>

          <div className="flex">
            <button
              className="px-[80px] py-[17px] rounded-[100px] border border-gray-90 mx-auto font-light tracking-tight leading-short text-gray-90"
              onClick={() => setSpreadProducts(!spreadProducts)}
            >
              <FormattedMessage
                id={
                  spreadProducts
                    ? "collection.button.fold"
                    : "collection.button.spread"
                }
              />
            </button>
          </div>
        </div>
      </MobileLayout>

      <div className="sticky w-full bottom-0 p-[16px] bg-white border-t border-t-gray-10">
        <button
          onClick={() => {
            track("click_contact", { brandId });
            navigate({
              pathname: "/brand/contact",
              search: `?id=${brandId}`,
            });
          }}
          className="w-full h-[56px] rounded-[100px] font-medium tracking-tight text-white bg-primary"
        >
          <FormattedMessage id="brand.contact.button" />
        </button>
      </div>
    </div>
  );
};
