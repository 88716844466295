import { instance } from "common/config/axios.config";

export const getCategories = async () => {
  try {
    const response = await instance.get("/categories");

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert(`오류\n\n${response.data.message}`);
    } else {
      alert("오류");
    }
  } catch (err: any) {
    alert("서버 오류");
  }
};
