import { LANGUAGE_OPTIONS } from "common/config";
import globalImages from "common/images";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

const DesktopHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showLanguageOptions, setShowLanguageOptions] =
    useState<boolean>(false);

  const _showLanguageOptions = () => {
    setShowLanguageOptions(!showLanguageOptions);
  };

  return (
    <div className="bg-white w-[1280px] h-[60px] flex items-center justify-between relative pl-[48px] pr-[162px]">
      <button
        className="outline-none"
        onClick={() => {
          if (location.pathname !== "/") {
            navigate("/");
          }
        }}
      >
        <img src={globalImages.logo} className="w-[108px]" alt="" />
      </button>
      <button
        onClick={() => {
          if (location.pathname !== "/curation") {
            navigate("/curation");
          }
        }}
        className="h-[32px] px-[16px] flex items-center rounded-[8px] hover:bg-gray-10"
      >
        <div className="text-[14px] font-medium text-gray-90 leading-short tracking-tight">
          <FormattedMessage id="header.curation.text" />
        </div>
      </button>
      <div
        className="absolute right-[36px] flex flex-col"
        style={{ zIndex: 100 }}
      >
        <button
          onClick={_showLanguageOptions}
          className="w-[110px] px-[12px] h-[36px] flex items-center gap-x-[4px] rounded-[8px] hover:bg-gray-10"
        >
          <img
            src={globalImages.icon.globe}
            className="w-[16px] h-[16px]"
            alt=""
          />
          <span className="text-[14px] font-[500] tracking-tight leading-short text-gray-90">
            <FormattedMessage id="language" />
          </span>
        </button>
        {showLanguageOptions && (
          <div
            className="absolute bg-white top-[44px] rounded-[8px] py-[12px] px-[8px] flex flex-col gap-y-[4px] w-full"
            style={{ boxShadow: "0px 4px 20px 0px rgba(103, 145, 179, 0.20)" }}
          >
            {LANGUAGE_OPTIONS.map((language, index) => (
              <button
                key={index}
                className="bg-white rounded-[4px] h-[28px] flex items-center justify-center text-[12px] text-gray-70 tracking-tight leading-short font-light hover:bg-gray-10"
                onClick={() => {
                  localStorage.setItem("locale", language.locale);
                  window.location.reload();
                }}
              >
                {language.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const MobileHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showLanguageOptions, setShowLanguageOptions] =
    useState<boolean>(false);

  const _showLanguageOptions = () => {
    setShowLanguageOptions(!showLanguageOptions);
  };

  return (
    <div className="w-full h-[50px] flex items-center justify-between relative pl-[16px] pr-[48px]">
      <button
        className="outline-none"
        onClick={() => {
          if (location.pathname !== "/") {
            navigate("/");
          }
        }}
      >
        <img src={globalImages.logo} className="w-[76px]" alt="" />
      </button>
      <button
        onClick={() => {
          if (location.pathname !== "/curation") {
            navigate("/curation");
          }
        }}
        className="h-[30px] flex px-[16px] items-center"
      >
        <div className="text-[13px] text-gray-90 font-medium leading-short tracking-tight">
          <FormattedMessage id="header.curation.text" />
        </div>
      </button>
      <div
        className="absolute right-0 flex flex-col items-end w-[120px]"
        style={{ zIndex: 100 }}
      >
        <button
          onClick={_showLanguageOptions}
          className="w-[44px] h-[44px] flex items-center justify-center mr-[3px]"
        >
          <img
            src={globalImages.icon.globe}
            className="w-[16px] h-[16px]"
            alt=""
          />
        </button>
        {showLanguageOptions && (
          <div
            className="absolute bg-white top-[44px] right-[16px] rounded-[8px] py-[12px] px-[8px] flex flex-col gap-y-[4px] w-full"
            style={{ boxShadow: "0px 4px 20px 0px rgba(103, 145, 179, 0.20)" }}
          >
            {LANGUAGE_OPTIONS.map((language, index) => (
              <button
                key={index}
                className="bg-white rounded-[4px] h-[28px] flex items-center justify-center text-[12px] text-gray-70 tracking-tight leading-short font-light"
                onClick={() => {
                  localStorage.setItem("locale", language.locale);
                  window.location.reload();
                }}
              >
                {language.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export { DesktopHeader, MobileHeader };
