import { track } from "@amplitude/analytics-browser";
import { MobileProductBox, MobileReviewBox } from "@components/boxes";
import { MobileLayout } from "@components/layouts";

import { MobileStars } from "@components/stars";
import { getBrandProducts } from "api/brands";
import { getProductDetail, getProductReviews } from "api/products";
import { assetsBaseUrl } from "common/config";
import globalImages from "common/images";
import { addDays, format } from "date-fns";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";

export const ProductMobile = () => {
  const width = window.innerWidth;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const productId = Number(searchParams.get("id"));

  const [product, setProduct] = useState<Product>();
  const [brandProducts, setBrandProducts] = useState<Product[]>([]);

  const [spreadDetails, setSpreadDetails] = useState<boolean>(false);
  const [reviews, setReviews] = useState<Review[]>([]);

  useEffect(() => {
    track("enter_product", { productId });
  }, [productId]);

  useEffect(() => {
    const fetch = async () => {
      const [productData, reviewData] = await Promise.all([
        getProductDetail(productId),
        getProductReviews(productId),
      ]);
      if (productData) {
        setProduct(productData);
        const brandProductsData = await getBrandProducts(productData.brand.id, {
          without: productId,
        });
        if (brandProductsData) {
          setBrandProducts(brandProductsData);
        }
      }
      if (reviewData) {
        setReviews(reviewData);
      }
    };

    fetch();
  }, [productId]);

  return (
    <div>
      <MobileLayout>
        {/* img */}
        <div
          className="flex overflow-x-auto"
          style={{ maxWidth: width, height: width }}
        >
          {product?.images.map((image, index) => (
            <div
              className="p-[30px] flex items-center justify-center"
              style={{ minWidth: width }}
              key={index}
            >
              <img src={assetsBaseUrl + image} className="w-full" alt="" />
            </div>
          ))}
        </div>

        {/* product information */}
        <div className="px-[16px] py-[24px]">
          <button
            onClick={() =>
              navigate({
                pathname: "/brand",
                search: `?id=${product?.brand.id}`,
              })
            }
            className="text-[12px] font-light leading-short tracking-tight mb-[4px] text-gray-90"
          >
            {product?.brand.name}
          </button>
          <div className="font-medium leading-short tracking-tight mb-[12px] text-gray-90">
            {product?.name}
          </div>
          <div className="flex gap-x-[4px] items-center mb-[24px]">
            <MobileStars rating={product?.rating ?? 0} />
            <span className="text-[12px] font-medium tracking-tight leading-short text-gray-70">
              {product?.rating}
            </span>
          </div>
          <div className="text-[12px] font-light leading-short tracking-tight mb-[24px] text-gray-70">
            {product?.capacity}
          </div>
          <div className="flex items-center mb-[36px] text-gray-90">
            <span className="text-[12px] font-light tracking-tight leading-short mr-[12px]">
              <FormattedMessage id="product.MSRP.label" />
            </span>
            <span className="text-[20px] font-medium leading-tall tracking-tight">
              ${((product?.msrp ?? 0) / 1300).toFixed(2).toLocaleString()}
            </span>
          </div>

          {/* guarantee */}
          <div className="bg-ivory rounded-[10px] p-[16px] text-gray-70 text-[13px]">
            <div className="mb-[8px] font-medium leading-short tracking-tight text-primary">
              <FormattedMessage id="product.guarantee.label" />
            </div>
            <div className="flex flex-col font-light leading-short tracking-tight">
              <div className="flex">
                <span className="mr-[4px] min-w-[16px] max-w-[16px] h-[16px]">
                  <img src={globalImages.icon.box} alt="" />
                </span>
                <span>
                  <span>
                    <FormattedMessage id="product.guarantee.text-1" />{" "}
                  </span>
                  <span>
                    <FormattedMessage id="product.guarantee.text-2" />{" "}
                    {product?.brand.name},{" "}
                  </span>
                  <span>
                    <FormattedMessage id="product.guarantee.text-3" />
                  </span>
                </span>
              </div>
              <br />
              <div className="flex">
                <span className="mr-[4px] min-w-[16px] max-w-[16px] h-[16px]">
                  <img src={globalImages.icon.box} alt="" />
                </span>
                <span>
                  <FormattedMessage id="product.guarantee.text-4" />
                </span>
              </div>
            </div>
          </div>

          {/* shipping */}
          <div className="p-[16px]">
            <div className="mb-[8px] text-[13px] font-medium leading-short tracking-tight text-gray-70">
              <FormattedMessage id="product.shipping.label" />
            </div>
            <div className="flex flex-col gap-y-[4px]">
              <div className="flex gap-x-[8px] text-gray-70">
                <span className="text-[14px] font-light leading-short tracking-tight">
                  •
                </span>
                <span className="text-[13px] leading-short tracking-tight">
                  <span className="font-light">
                    <FormattedMessage id="product.shipping.text-1" />{" "}
                  </span>
                  <span className="font-medium">7-18 days</span>
                </span>
              </div>
              <div className="flex gap-x-[8px] text-gray-70">
                <span className="text-[14px] font-light leading-short tracking-tight">
                  •
                </span>
                <span className="text-[13px] leading-short tracking-tight">
                  <span className="font-light">
                    <FormattedMessage id="product.shipping.text-2" />{" "}
                  </span>
                  <span className="font-medium">$ 5.00</span>
                </span>
              </div>
              <div className="flex gap-x-[8px] text-gray-70">
                <span className="text-[14px] font-light leading-short tracking-tight">
                  •
                </span>
                <div className="flex flex-col text-[13px] leading-short tracking-tight">
                  <span>
                    <span className="font-light">
                      <FormattedMessage id="product.shipping.text-3.1" />{" "}
                    </span>
                    <span className="font-medium">
                      {format(addDays(new Date(), 7), "MMM d, Y")} -{" "}
                      {format(addDays(new Date(), 18), "MMM d, Y")}
                    </span>
                  </span>
                  <span className="font-light">
                    <FormattedMessage id="product.shipping.text-3.2" />
                  </span>
                </div>
              </div>
              <div className="flex gap-x-[8px] text-error">
                <span className="text-[14px] font-light leading-short tracking-tight">
                  •
                </span>
                <span className="text-[13px] leading-short tracking-tight font-light">
                  <FormattedMessage id="product.shipping.text-4" />{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* product detail */}
        <div className="px-[16px]">
          <div className="text-[16px] font-medium leading-short tracking-tight mb-[12px] text-gray-90">
            <FormattedMessage id="product.detail.label" />
          </div>
          <div className="p-[16px] bg-gray-10 rounded-[12px] mb-[36px]">
            <div
              className={`text-[12px] font-light leading-short tracking-tight mb-[7px] text-gray-70 ${
                spreadDetails ? "" : "line-clamp-4"
              }`}
            >
              {product?.description?.split("\n\n").map((paragraph, index) => (
                <div key={`paragraph-${index}`}>
                  {index > 0 && <br />}
                  {paragraph.split("\n").map((line, index) => (
                    <div key={`line-${index}`}>{line}</div>
                  ))}
                </div>
              )) ?? ""}
            </div>
            <div className="flex">
              <button
                className="mx-auto"
                onClick={() => setSpreadDetails(!spreadDetails)}
              >
                <img
                  src={
                    spreadDetails
                      ? globalImages.chevron.up
                      : globalImages.chevron.down
                  }
                  className="w-[24px] h-[24px]"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
        {/* reviews */}
        <div className="mb-[36px]">
          <div className="text-[16px] font-medium leading-short tracking-tight mb-[12px] text-gray-90 px-[16px]">
            <FormattedMessage id="product.reviews.label" />
          </div>
          <div
            className="flex gap-x-[8px] overflow-x-auto px-[16px]"
            style={{
              maxWidth: width,
            }}
          >
            {reviews.map((review, index) => (
              <MobileReviewBox review={review} key={index} />
            ))}
          </div>
        </div>
        <div className="px-[16px] mb-[72px]">
          <div className="mb-[36px] flex justify-between items-center">
            <span className="text-[16px] font-medium leading-short tracking-tight text-gray-90">
              <FormattedMessage id="product.more.label" /> {product?.brand.name}
            </span>
            <button
              onClick={() => {
                track("click_see_all");
                navigate({
                  pathname: "/brand",
                  search: `?id=${product?.brand.id}`,
                });
              }}
              className="text-[12px] font-light leading-short tracking- underline text-gray-70"
            >
              <FormattedMessage id="product.more.button" />
            </button>
          </div>
          <div className="grid grid-cols-2 gap-x-[8px] gap-y-[32px]">
            {brandProducts.slice(0, 4).map((product, index) => (
              <MobileProductBox product={product} key={index} />
            ))}
          </div>
        </div>
      </MobileLayout>

      <div className="sticky w-full bottom-0 p-[16px] bg-white border-t border-t-gray-10">
        <button
          onClick={() => {
            track("click_request", { productId });
            navigate({
              pathname: "/product/contact",
              search: `?id=${product?.id}`,
            });
          }}
          className="w-full h-[56px] rounded-[100px] font-medium tracking-tight text-white bg-primary"
        >
          <FormattedMessage id="product.contact.label" />
        </button>
      </div>
    </div>
  );
};
