import { track } from "@amplitude/analytics-browser";
import { DesktopLayout } from "@components/layouts";

import { DesktopStars } from "@components/stars";
import { getBrands } from "api/brands";
import { getProducts } from "api/products";
import { assetsBaseUrl } from "common/config";
import { CATALOG_DOWNLOAD_LINK } from "common/data";
import globalImages from "common/images";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

export const HomeDesktop = () => {
  const navigate = useNavigate();

  const [products, setProducts] = useState<Product[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);

  useEffect(() => {
    track("enter_home");
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const [products, brands] = await Promise.all([
        getProducts(),
        getBrands({ sort: "amazon" }),
      ]);
      if (products) {
        setProducts(products);
      }
      if (brands) {
        setBrands(brands);
      }
    };
    fetch();
  }, []);

  const _openCatalog = () => {
    window.open(CATALOG_DOWNLOAD_LINK);
  };

  return (
    <DesktopLayout>
      {/* 배너 */}
      <div
        className="bg-[url('./assets/images/home-banner.png')] bg-cover w-screen absolute flex justify-center left-0"
        style={{ zIndex: 50 }}
      >
        <div className="w-[1280px] h-[500px] flex flex-col px-[48px] justify-center items-start">
          <div className="flex flex-col text-[50px] font-bold tracking-tight leading-tall mb-[24px]">
            <span className="text-beige">
              <FormattedMessage id="home.banner.title-1" />
            </span>
            <span className="text-white">
              <FormattedMessage id="home.banner.title-2" />
            </span>
          </div>
          <div className="flex flex-col text-[28px] font-light tracking-tight leading-tall text-white mb-[48px]">
            <span>
              <FormattedMessage id="home.banner.text-1" />
            </span>
            <span>
              <FormattedMessage id="home.banner.text-2" />
            </span>
          </div>
          <button
            onClick={() => {
              track("click_cta1");
              _openCatalog();
            }}
            className="flex items-center justify-center px-[24px] rounded-[200px] h-[52px] bg-beige text-[14px] font-medium tracking-tight leading-short text-gray-90"
          >
            <FormattedMessage id="home.banner.cta" />
          </button>
        </div>
      </div>

      <div className="mt-[480px] px-[48px] py-[120px] bg-white">
        {/* benefit */}
        <div className="flex gap-x-[16px] mb-[120px]">
          <span className="bg-blue flex-1 flex flex-col items-center justify-center text-center px-[40px] py-[36px] gap-y-[16px] rounded-[20px]">
            <div className="text-[28px] font-medium tracking-tight leading-tall text-beige">
              <FormattedMessage id="home.benefit-1.title" />
            </div>
            <div className="text-[16px] font-light tracking-tight leading-short text-white">
              <FormattedMessage id="home.benefit-1.text" />
            </div>
          </span>

          <span className="bg-blue flex-1 flex flex-col items-center text-center justify-center px-[40px] py-[36px] gap-y-[16px] rounded-[20px]">
            <div className="text-[28px] font-medium tracking-[-0.64px] leading-[110%] text-[#E7DBCD]">
              <FormattedMessage id="home.benefit-2.title" />
            </div>
            <div className="text-[16px] font-light tracking-tight leading-short text-white">
              <FormattedMessage id="home.benefit-2.text" />
            </div>
          </span>

          <span className="bg-blue flex-1 flex flex-col items-center text-center justify-center px-[40px] py-[36px] gap-y-[16px] rounded-[20px]">
            <div className="text-[28px] font-medium tracking-[-0.64px] leading-[110%] text-[#E7DBCD]">
              <FormattedMessage id="home.benefit-3.title" />
            </div>
            <div className="text-[16px] font-light tracking-tight leading-short text-white">
              <FormattedMessage id="home.benefit-3.text" />
            </div>
          </span>
        </div>

        {/* best products */}
        <div className="flex flex-col mb-[120px]">
          <div className="text-center text-[32px] font-bold text-gray-90">
            <FormattedMessage id="home.bestproducts.title" />
          </div>
          <div className="flex gap-x-[16px] py-[36px]">
            {products.splice(0, 4).map((product, index) => (
              <button
                onClick={() => {
                  navigate({
                    pathname: "/product",
                    search: `?id=${product.id}`,
                  });
                }}
                key={index}
                className="flex-1 bg-gray-10 rounded-[20px] p-[32px] flex flex-col justify-between"
              >
                <div className="flex flex-col gap-y-[16px] mb-[34px]">
                  <div className="text-[20px] font-medium leading-tall tracking-tight text-gray-90 line-clamp-2">
                    {product.name}
                  </div>
                  <div className="flex items-center gap-x-[4px]">
                    <DesktopStars rating={product.rating} />
                    <span className="text-[14px] font-medium text-gray-70 tracking-tight">
                      {product.rating}
                    </span>
                  </div>
                </div>
                <img
                  src={assetsBaseUrl + product.images[0]}
                  className="w-[200px] aspect-square mx-auto rounded-[8px]"
                  alt=""
                />
              </button>
            ))}
          </div>
          <div className="w-full flex justify-center">
            <button
              className="px-[16px] py-[10px] rounded-[100px] bg-gray-90 text-white text-[14px] font-medium leading-short tracking-tight"
              onClick={() => {
                track("click_button1");
                navigate("/collection");
              }}
            >
              <FormattedMessage id="home.bestproducts.button" />
            </button>
          </div>
        </div>

        {/* our points */}
        <div className="flex justify-end bg-[#F1DFCC] rounded-[20px] h-[276px] mb-[120px] bg-[url('./assets/images/home-points-background.png')] bg-cover">
          <div className="flex flex-col justify-center mr-[110px] gap-y-[16px] text-[20px] tracking-tight leading-tall font-medium text-gray-90">
            <span className="flex items-center gap-x-[8px]">
              <img
                src={globalImages.icon.check.circle.blue}
                className="w-[24px] h-[24px]"
                alt=""
              />
              <FormattedMessage id="home.points.text-1" />
            </span>
            <span className="flex items-center gap-x-[8px]">
              <img
                src={globalImages.icon.check.circle.blue}
                className="w-[24px] h-[24px]"
                alt=""
              />
              <FormattedMessage id="home.points.text-2" />
            </span>
            <span className="flex items-center gap-x-[8px]">
              <img
                src={globalImages.icon.check.circle.blue}
                className="w-[24px] h-[24px]"
                alt=""
              />
              <FormattedMessage id="home.points.text-3" />
            </span>
          </div>
        </div>

        {/* popular brands */}
        <div className="flex mb-[120px]">
          <div className="flex grow gap-x-[16px]">
            {brands.splice(0, 3).map((brand, index) => (
              <button
                onClick={() => {
                  navigate({
                    pathname: "/brand",
                    search: `?id=${brand.id}`,
                  });
                }}
                className="flex-1 bg-gray-10 rounded-[20px] flex justify-center items-center aspect-square border border-gray-20"
                key={index}
              >
                <img
                  src={assetsBaseUrl + brand.image}
                  className="w-full rounded-[20px]"
                  alt=""
                />
              </button>
            ))}
            <div className="flex-1 flex flex-col x-[28px] gap-y-[48px] justify-center">
              <div className="flex flex-col gap-y-[16px]">
                <div className="text-[24px] font-medium tracking-tight leading-tall text-gray-90">
                  <FormattedMessage id="home.brands.title" />
                </div>
                <div className="text-[16px] font-light leading-short tracking-tight text-gray-70">
                  <FormattedMessage id="home.brands.description" />
                </div>
              </div>
              <div>
                <button
                  className="bg-gray-90 text-white rounded-[100px] text-[14px] font-medium tracking-tight px-[16px] py-[10px]"
                  onClick={() => {
                    track("click_button2");
                    navigate("/collection");
                  }}
                >
                  <FormattedMessage id="home.brands.button" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* unique values */}
        <div className="mb-[120px]">
          <div className="text-[32px] font-bold leading-tall tracking-tight mb-[36px] text-gray-90">
            <FormattedMessage id="home.unique.title" />
          </div>
          <div className="flex gap-x-[16px]">
            <span className="flex-1">
              <div className="text-[20px] font-medium text-primary leading-tall tracking-tight mb-[16px]">
                <FormattedMessage id="home.unique-1.title" />
              </div>
              <div className="leading-short tracking-tight font-light text-gray-70">
                <FormattedMessage id="home.unique-1.content" />
              </div>
            </span>
            <span className="flex-1">
              <div className="text-[20px] font-medium text-primary leading-tall tracking-tight mb-[16px]">
                <FormattedMessage id="home.unique-2.title" />
              </div>
              <div className="leading-short tracking-tight font-light text-gray-70">
                <FormattedMessage id="home.unique-2.content" />
              </div>
            </span>
            <span className="flex-1">
              <div className="text-[20px] font-medium text-primary leading-tall tracking-tight mb-[16px]">
                <FormattedMessage id="home.unique-3.title" />
              </div>
              <div className="leading-short tracking-tight font-light text-gray-70">
                <FormattedMessage id="home.unique-3.content" />
              </div>
            </span>
            <span className="flex-1">
              <div className="text-[20px] font-medium text-primary leading-tall tracking-tight mb-[16px]">
                <FormattedMessage id="home.unique-4.title" />
              </div>
              <div className="leading-short tracking-tight font-light text-gray-70">
                <FormattedMessage id="home.unique-4.content" />
              </div>
            </span>
          </div>
        </div>

        {/* cta */}
        <div className="h-[288px] bg-[url('./assets/images/home-cta-background.png')] bg-cover flex flex-col items-center justify-center rounded-[20px]">
          <div className="text-[32px] text-white tracking-tight leading-tall w-[580px] text-center mb-[36px]">
            <FormattedMessage id="home.cta.text" />
          </div>
          <button
            onClick={() => {
              track("click_cta2");
              _openCatalog();
            }}
            className="h-[52px] rounded-[200px] px-[20px] bg-gray-90 text-white text-[14px] font-medium leading-short tracking-tight"
          >
            <FormattedMessage id="home.cta.button" />
          </button>
        </div>
      </div>
    </DesktopLayout>
  );
};
