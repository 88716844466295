import { track } from "@amplitude/analytics-browser";
import { MobileLayout } from "@components/layouts";

import { postInquiries } from "api/inquiries";
import { getProductDetail } from "api/products";
import { assetsBaseUrl } from "common/config";
import {
  ANNUAL_SALES_LIST,
  BUSINESS_TYPE_LIST,
  PRIVACY_POLICY_LINK,
  TERMS_OF_SERVICE_LINK,
} from "common/data";
import globalImages from "common/images";
import replace from "lodash/replace";
import { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export const ProductContactMobile = () => {
  const width = window.innerWidth;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const productId = Number(searchParams.get("id"));

  const [product, setProduct] = useState<Product>();

  const [quantity, setQuantity] = useState<string>("");
  const [description, setDescription] =
    useState<ProductInquiriesBody["description"]>("");
  const [requestSample, setRequestSample] =
    useState<ProductInquiriesBody["sample"]>(false);

  const [firstName, setFirstName] =
    useState<ProductInquiriesBody["firstName"]>("");
  const [lastName, setLastName] =
    useState<ProductInquiriesBody["lastName"]>("");
  const [email, setEmail] = useState<ProductInquiriesBody["email"]>("");
  const [countryList, setCountryList] = useState<any>([]);
  const [country, setCountry] = useState({
    code: "US",
    name: "United States of America",
  });
  const [spreadCountryList, setSpreadCountryList] = useState<boolean>(false);

  const [companyName, setCompanyName] =
    useState<ProductInquiriesBody["companyName"]>("");
  const [companyUrl, setCompanyUrl] =
    useState<ProductInquiriesBody["companyUrl"]>(null);
  const [businessKind, setBusinessKind] =
    useState<ProductInquiriesBody["businessKind"]>(null);
  const [annualSales, setAnnualSales] =
    useState<ProductInquiriesBody["annualSales"]>(null);

  const [spreadBusinessKindList, setSpreadBusinessKindList] =
    useState<boolean>(false);
  const [spreadAnnualSalesList, setSpreadAnnualSalesList] =
    useState<boolean>(false);

  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const [privacyAgreed, setPrivacyAgreed] = useState<boolean>(false);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    track("enter_request", { productId });
  }, [productId]);

  useEffect(() => {
    const fetch = async () => {
      const productData = await getProductDetail(productId);
      if (productData) {
        setProduct(productData);
      }
    };

    fetch();
  }, [productId]);

  useEffect(() => {
    const countryList = Object.entries(countries.getNames("en")).map(
      ([key, value]) => {
        return { code: key, name: value };
      }
    );
    setCountryList(countryList);
  }, []);

  useEffect(() => {
    if (
      description === "" ||
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      companyName === "" ||
      !termsAgreed ||
      !privacyAgreed
    ) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [
    description,
    firstName,
    lastName,
    email,
    companyName,
    companyUrl,
    termsAgreed,
    privacyAgreed,
  ]);

  const _request = async () => {
    if (window.gtag) {
      const { gtag } = window;
      gtag("event", "conversion", {
        send_to: "AW-11145749137/HEiLCKL5-IQZEJHF2sIp",
      });
    }
    ReactPixel.fbq("track", "Product Request");

    const body = {
      productId,
      quantity: Number(quantity),
      description,
      sample: requestSample,
      firstName,
      lastName,
      email,
      country: country.code,
      companyName,
      companyUrl,
      businessKind,
      annualSales,
    };
    const response = await postInquiries(body);
    if (response && response.success) {
      track("request_done", { productId });
      navigate("/");
    }
  };

  return (
    <MobileLayout>
      <div className="w-screen px-[16px] pt-[32px] pb-[72px]">
        {/* title */}
        <div className="text-[20px] font-bold leading-tall tracking-tight text-gray-90 mb-[24px] text-center">
          <FormattedMessage id="product.contact.title" />
        </div>

        {/* product information */}
        <div className="flex">
          <div className="min-w-[84px] max-w-[84px] aspect-square rounded-[8px] border border-gray-10 mr-[8px]">
            <img
              src={assetsBaseUrl + product?.images[0]}
              className="rounded-[8px]"
              alt=""
            />
          </div>

          <div className="grow flex flex-col justify-center text-gray-70">
            <div className="text-[12px] font-light leading-short tracking-tight mb-[4px]">
              {product?.brand.name}
            </div>
            <div
              className="text-[14px] font-medium leading-short tracking-tight mb-[8px] overflow-hidden"
              style={{ maxWidth: width - 124 }}
            >
              {product?.name}
            </div>
            <div className="text-[12px] font-light leading-short tracking-tight">
              {product?.capacity}
            </div>
          </div>
        </div>

        <div className="h-[1px] bg-gray-10 my-[36px]" />

        {/* request forms */}
        <div>
          <div className="font-bold text-gray-90 leading-short tracking-tight mb-[16px]">
            <FormattedMessage id="product.contact.inquiry.title" />
          </div>
          {/* quantity */}
          <div className="mb-[20px]">
            <div className="text-[14px] font-light tracking-tight leading-short text-gray-70 mb-[8px] px-[4px]">
              <FormattedMessage id="product.contact.quantity.label" />
            </div>
            <input
              className="w-full h-[48px] px-[16px] border border-gray-20 rounded-[8px] outline-none font-light tracking-tight text-gray-90"
              value={quantity}
              onChange={(e) =>
                setQuantity(replace(e.target.value, /[^0-9]/g, ""))
              }
            />
          </div>
          {/* inquiry detail */}
          <div className="mb-[20px]">
            <div className="text-[14px] font-light tracking-tight leading-short text-gray-70 mb-[8px] px-[4px]">
              <FormattedMessage id="product.contact.inquiry.label" />
            </div>
            <textarea
              className="w-full h-[296px] px-[16px] py-[13px] border border-gray-20 rounded-[8px] outline-none font-light leading-short tracking-tight resize-none text-gray-90"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          {/* request sample check */}
          <div className="flex items-center mb-[20px]">
            <button onClick={() => setRequestSample(!requestSample)}>
              <img
                src={
                  requestSample
                    ? globalImages.checkbox.square.active
                    : globalImages.checkbox.square.inactive
                }
                className="w-[24px] h-[24px] mr-[4px]"
                alt=""
              />
            </button>
            <span className="font-light text-gray-70 tracking-tight leading-short">
              <FormattedMessage id="product.contact.sample.text" />
            </span>
          </div>
          {/* check remind */}
          <div className="px-[24px] py-[16px] bg-ivory rounded-[8px]">
            <div className="flex items-center mb-[8px]">
              <img
                src={globalImages.icon.exclamation}
                className="w-[16px] h-[16px] mr-[4px]"
                alt=""
              />
              <span className="text-[13px] font-medium text-error leading-short tracking-tight">
                <FormattedMessage id="product.contact.check.title" />
              </span>
            </div>
            <div className="text-[13px] font-light text-gray-70 leading-short tracking-tight flex flex-col gap-y-[4px]">
              <div className="flex">
                <span className="mr-[8px]">•</span>
                <span>
                  <FormattedMessage id="product.contact.check-1" />
                </span>
              </div>
              <div className="flex">
                <span className="mr-[8px]">•</span>
                <span>
                  <FormattedMessage id="product.contact.check-2" />
                </span>
              </div>
              <div className="flex">
                <span className="mr-[8px]">•</span>
                <span>
                  <FormattedMessage id="product.contact.check-3" />
                </span>
              </div>
            </div>
          </div>
          <div className="h-[1px] bg-gray-10 my-[32px]" />
          <div className="font-bold text-gray-90 leading-short tracking-tight mb-[16px]">
            <FormattedMessage id="product.contact.customer.title" />
          </div>
          {/* first name */}
          <div className="mb-[20px]">
            <div className="text-[14px] font-light tracking-tight leading-short text-gray-70 mb-[8px] px-[4px]">
              <FormattedMessage id="product.contact.customer.firstname" />
            </div>
            <input
              className="w-full h-[48px] px-[16px] border border-gray-20 rounded-[8px] outline-none font-light tracking-tight text-gray-90"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          {/* last name */}
          <div className="mb-[20px]">
            <div className="text-[14px] font-light tracking-tight leading-short text-gray-70 mb-[8px] px-[4px]">
              <FormattedMessage id="product.contact.customer.lastname" />
            </div>
            <input
              className="w-full h-[48px] px-[16px] border border-gray-20 rounded-[8px] outline-none font-light tracking-tight text-gray-90"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          {/* email */}
          <div className="w-full mb-[20px]">
            <div className="text-[14px] font-light tracking-tight leading-short text-gray-70 mb-[8px] px-[4px]">
              <FormattedMessage id="product.contact.customer.email" />
            </div>
            <input
              className="w-full h-[48px] px-[16px] border border-gray-20 rounded-[8px] outline-none font-light tracking-tight text-gray-90"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {/* country */}
          <div className="w-full">
            <div className="text-[14px] font-light tracking-tight leading-short text-gray-70 mb-[8px] px-[4px]">
              <FormattedMessage id="product.contact.customer.country" />
            </div>
            <div className="font-light tracking-tight leading-short text-gray-90 relative">
              <button
                className="w-full h-[48px] px-[16px] bg-white rounded-[8px] border border-gray-20 flex items-center justify-between"
                onClick={() => setSpreadCountryList(!spreadCountryList)}
              >
                <span>{country.name}</span>
                <img
                  src={
                    spreadCountryList
                      ? globalImages.chevron.up
                      : globalImages.chevron.down
                  }
                  className="w-[20px] h-[20px]"
                  alt=""
                />
              </button>
              {spreadCountryList && (
                <div className="absolute w-full max-h-[324px] overflow-y-auto px-[12px] py-[18px] mt-[18px] border border-gray-20 rounded-[8px] flex flex-col gap-y-[1px] bg-white">
                  {countryList.map((country: any, index: number) => (
                    <button
                      key={index}
                      onClick={() => {
                        setCountry(country);
                        setSpreadCountryList(false);
                      }}
                      className="min-h-[48px] flex items-center rounded-[8px] px-[16px] hover:bg-gray-10"
                    >
                      {country.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="h-[1px] bg-gray-10 my-[32px]" />
          <div className="font-bold text-gray-90 leading-short tracking-tight mb-[16px]">
            <FormattedMessage id="product.contact.business.title" />
          </div>
          {/* company name */}
          <div className="w-full mb-[20px]">
            <div className="text-[14px] font-light tracking-tight leading-short text-gray-70 mb-[8px] px-[4px]">
              <FormattedMessage id="product.contact.business.company" />
            </div>
            <input
              className="w-full h-[48px] px-[16px] border border-gray-20 rounded-[8px] outline-none font-light tracking-tight text-gray-90"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          {/* company url */}
          <div className="w-full mb-[20px]">
            <div className="flex items-center mb-[8px] px-[4px] gap-x-[4px]">
              <span className="text-[14px] font-light tracking-tight leading-short text-gray-70">
                <FormattedMessage id="product.contact.business.url" />
              </span>
              <span className="text-[12px] font-light tracking-tight leading-short text-error">
                (<FormattedMessage id="contact.optional.label" />)
              </span>
            </div>
            <input
              className="w-full h-[48px] px-[16px] border border-gray-20 rounded-[8px] outline-none font-light tracking-tight text-gray-90"
              value={companyUrl ?? ""}
              onChange={(e) => setCompanyUrl(e.target.value)}
            />
          </div>

          {/* business kind */}
          <div className="w-full mb-[20px]">
            <div className="flex items-center mb-[8px] px-[4px] gap-x-[4px]">
              <span className="text-[14px] font-light tracking-tight leading-short text-gray-70">
                <FormattedMessage id="product.contact.business.type" />
              </span>
              <span className="text-[12px] font-light tracking-tight leading-short text-error">
                (<FormattedMessage id="contact.optional.label" />)
              </span>
            </div>
            <div className="font-light tracking-tight leading-short text-gray-90 relative">
              <button
                className="w-full h-[48px] px-[16px] bg-white rounded-[8px] border border-gray-20 flex items-center justify-between"
                onClick={() =>
                  setSpreadBusinessKindList(!spreadBusinessKindList)
                }
              >
                <span>{businessKind}</span>
                <img
                  src={
                    spreadBusinessKindList
                      ? globalImages.chevron.up
                      : globalImages.chevron.down
                  }
                  className="w-[20px] h-[20px]"
                  alt=""
                />
              </button>
              {spreadBusinessKindList && (
                <div
                  className="absolute w-full overflow-y-auto px-[12px] py-[18px] mt-[18px] border border-gray-20 rounded-[8px] flex flex-col gap-y-[1px] bg-white"
                  style={{ zIndex: 150 }}
                >
                  {BUSINESS_TYPE_LIST.map((type: string, index: number) => (
                    <button
                      key={index}
                      onClick={() => {
                        setBusinessKind(type);
                        setSpreadBusinessKindList(false);
                      }}
                      className="min-h-[48px] flex items-center rounded-[8px] px-[16px] hover:bg-gray-10"
                    >
                      {type}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* annual sales */}
          <div className="w-full mb-[36px]">
            <div className="flex items-center mb-[8px] px-[4px] gap-x-[4px]">
              <span className="text-[14px] font-light tracking-tight leading-short text-gray-70">
                <FormattedMessage id="product.contact.business.sales" />
              </span>
              <span className="text-[12px] font-light tracking-tight leading-short text-error">
                (<FormattedMessage id="contact.optional.label" />)
              </span>
            </div>
            <div className="font-light tracking-tight leading-short text-gray-90 relative">
              <button
                className="w-full h-[48px] px-[16px] bg-white rounded-[8px] border border-gray-20 flex items-center justify-between"
                onClick={() => setSpreadAnnualSalesList(!spreadAnnualSalesList)}
              >
                <span>{annualSales}</span>
                <img
                  src={
                    spreadAnnualSalesList
                      ? globalImages.chevron.up
                      : globalImages.chevron.down
                  }
                  className="w-[20px] h-[20px]"
                  alt=""
                />
              </button>
              {spreadAnnualSalesList && (
                <div className="absolute w-full overflow-y-auto px-[12px] py-[18px] mt-[18px] border border-gray-20 rounded-[8px] flex flex-col gap-y-[1px] bg-white">
                  {ANNUAL_SALES_LIST.map((sales: string, index: number) => (
                    <button
                      key={index}
                      onClick={() => {
                        setAnnualSales(sales);
                        setSpreadAnnualSalesList(false);
                      }}
                      className="min-h-[48px] flex items-center rounded-[8px] px-[16px] hover:bg-gray-10"
                    >
                      {sales}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* agrees */}
          <div className="w-full flex flex-col gap-y-[8px] mb-[60px]">
            <span className="flex items-center">
              <button onClick={() => setTermsAgreed(!termsAgreed)}>
                <img
                  src={
                    termsAgreed
                      ? globalImages.checkbox.square.active
                      : globalImages.checkbox.square.inactive
                  }
                  className="w-[24px] h-[24px] mr-[4px]"
                  alt=""
                />
              </button>
              <button
                onClick={() => window.open(TERMS_OF_SERVICE_LINK)}
                className="font-light text-gray-70 tracking-tight leading-short"
              >
                <FormattedMessage id="product.contact.agree-1" />
              </button>
            </span>
            <span className="flex items-center">
              <button onClick={() => setPrivacyAgreed(!privacyAgreed)}>
                <img
                  src={
                    privacyAgreed
                      ? globalImages.checkbox.square.active
                      : globalImages.checkbox.square.inactive
                  }
                  className="w-[24px] h-[24px] mr-[4px]"
                  alt=""
                />
              </button>
              <button
                onClick={() => window.open(PRIVACY_POLICY_LINK)}
                className="font-light text-gray-70 tracking-tight leading-short"
              >
                <FormattedMessage id="product.contact.agree-2" />
              </button>
            </span>
          </div>

          <div className="flex justify-center">
            <button
              onClick={_request}
              disabled={buttonDisabled}
              className="h-[56px] px-[36px] rounded-[100px] bg-primary text-white font-medium leading-short tracking-tight flex items-center justify-center"
            >
              <FormattedMessage id="product.contact.request.label" />
            </button>
          </div>
        </div>
      </div>
    </MobileLayout>
  );
};
