const globalImages = {
  logo: require("@images/cosmarie-logo.png"),
  banner: require("@images/home-banner.png"),
  background: {
    home: {
      points: {
        desktop: require("@images/home-points-background.png"),
        mobile: require("@images/home-points-background-mobile.png"),
      },
    },
    product: {
      points: require("@images/product-points-background.png"),
    },
  },
  test: require("@images/test.png"),
  chevron: {
    circle: {
      left: require("@images/chevron-circle-left.png"),
      right: require("@images/chevron-circle-right.png"),
    },
    up: require("@images/chevron-up.png"),
    down: require("@images/chevron-down.png"),
    left: require("@images/chevron-left.png"),
    right: require("@images/chevron-right.png"),
  },
  arrow: {
    circle: {
      right: require("@images/arrow-circle-right.png"),
    },
  },
  icon: {
    box: require("@images/box-icon.png"),
    exclamation: require("@images/circle-exclamation.png"),
    globe: require("@images/globe.png"),
    star: {
      full: require("@images/star.png"),
      half: require("@images/star-half.png"),
    },
    check: {
      circle: {
        blue: require("@images/circle-check-blue.png"),
      },
      blue: require("@images/check-blue.png"),
    },
    arrowRight: require("@images/arrow-right-icon.png"),
  },
  checkbox: {
    square: {
      active: require("@images/checkbox-square-active.png"),
      inactive: require("@images/checkbox-square-inactive.png"),
    },
  },
  curation: [
    require("@images/curation-pick-fixed-1.png"),
    require("@images/curation-pick-fixed-2.png"),
  ],
};

export default globalImages;
