import { instance } from "common/config/axios.config";

export const getBrands = async (params: GetBrandsParams) => {
  try {
    const response = await instance.get("/brands", { params });

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert(`오류\n\n${response.data.message}`);
    } else {
      alert("오류");
    }
  } catch (err: any) {
    alert("서버 오류");
  }
};

export const getBrandDetail = async (brandId: number) => {
  try {
    const response = await instance.get(`/brands/${brandId}`);

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert(`오류\n\n${response.data.message}`);
    } else {
      alert("오류");
    }
  } catch (err: any) {
    alert("서버 오류");
  }
};

export const getBrandProducts = async (
  brandId: number,
  params?: GetBrandProductsParams
) => {
  try {
    const response = await instance.get(`/brands/${brandId}/products`, {
      params,
    });

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert(`오류\n\n${response.data.message}`);
    } else {
      alert("오류");
    }
  } catch (err: any) {
    alert("서버 오류");
  }
};
