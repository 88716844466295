import axios from "axios";

const baseUrl = "https://api.cosmarie.com";

export const instance = axios.create({
  baseURL: baseUrl,
});
instance.interceptors.request.use((config) => {
  const locale = localStorage.getItem("locale");
  if (locale) {
    config.headers["accept-language"] = locale;
  }
  return config;
});
