import {
  MAX_MOBILE_SIZE,
  MIN_DESKTOP_SIZE,
} from "common/config/responsive.config";
import { createContext } from "react";
import { useMediaQuery } from "react-responsive";

interface Props {
  children?: React.ReactNode;
}

const globalContext = createContext({
  isDesktop: false,
  isMobile: false,
});

const GlobalContextProvider = ({ children }: Props) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${MIN_DESKTOP_SIZE}px)`,
  });
  const isMobile = useMediaQuery({
    query: `(max-width: ${MAX_MOBILE_SIZE}px)`,
  });

  return (
    <globalContext.Provider value={{ isDesktop, isMobile }}>
      {children}
    </globalContext.Provider>
  );
};

export { GlobalContextProvider, globalContext };
