import { instance } from "common/config/axios.config";

export const getProducts = async (params?: GetProductsParams) => {
  try {
    const response = await instance.get("/products", { params });

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert(`오류\n\n${response.data.message}`);
    } else {
      alert("오류");
    }
  } catch (err: any) {
    alert("서버 오류");
  }
};

export const getProductDetail = async (productId: number) => {
  try {
    const response = await instance.get(`/products/${productId}`);

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert(`오류\n\n${response.data.message}`);
    } else {
      alert("오류");
    }
  } catch (err: any) {
    alert("서버 오류");
  }
};

export const getProductReviews = async (productId: number) => {
  try {
    const response = await instance.get(`/products/${productId}/reviews`);

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else if (response.status >= 400 && response.status < 500) {
      alert(`오류\n\n${response.data.message}`);
    } else {
      alert("오류");
    }
  } catch (err: any) {
    alert("서버 오류");
  }
};
