import { Desktop, Mobile } from "@components/responsive";
import { BrandDesktop } from "./desktop/brand";
import { BrandContactDesktop } from "./desktop/brand/contact";
import { CollectionDesktop } from "./desktop/collection";
import { CurationDesktop } from "./desktop/curation";
import { HomeDesktop } from "./desktop/home";
import { ProductDesktop } from "./desktop/product";
import { ProductContactDesktop } from "./desktop/product/contact";
import { CurationMobile } from "./mobile/curation";
import { BrandMobile } from "./mobile/brand";
import { BrandContactMobile } from "./mobile/brand/contact";
import { CollectionMobile } from "./mobile/collection";
import { HomeMobile } from "./mobile/home";
import { ProductMobile } from "./mobile/product";
import { ProductContactMobile } from "./mobile/product/contact";

export const Home = () => {
  return (
    <>
      <Desktop>
        <HomeDesktop />
      </Desktop>

      <Mobile>
        <HomeMobile />
      </Mobile>
    </>
  );
};

export const Collection = () => {
  return (
    <>
      <Desktop>
        <CollectionDesktop />
      </Desktop>

      <Mobile>
        <CollectionMobile />
      </Mobile>
    </>
  );
};

export const Product = () => {
  return (
    <>
      <Desktop>
        <ProductDesktop />
      </Desktop>

      <Mobile>
        <ProductMobile />
      </Mobile>
    </>
  );
};

export const ProductContact = () => {
  return (
    <>
      <Desktop>
        <ProductContactDesktop />
      </Desktop>

      <Mobile>
        <ProductContactMobile />
      </Mobile>
    </>
  );
};

export const Brand = () => {
  return (
    <>
      <Desktop>
        <BrandDesktop />
      </Desktop>

      <Mobile>
        <BrandMobile />
      </Mobile>
    </>
  );
};

export const BrandContact = () => {
  return (
    <>
      <Desktop>
        <BrandContactDesktop />
      </Desktop>

      <Mobile>
        <BrandContactMobile />
      </Mobile>
    </>
  );
};

export const Curation = () => {
  return (
    <>
      <Desktop>
        <CurationDesktop />
      </Desktop>

      <Mobile>
        <CurationMobile />
      </Mobile>
    </>
  );
};
