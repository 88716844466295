import { track } from "@amplitude/analytics-browser";
import { assetsBaseUrl } from "common/config";
import globalImages from "common/images";
import { useNavigate } from "react-router-dom";
import { DesktopStars, MobileStars } from "./stars";

interface ProductBoxProps {
  product: Product;
}
interface BrandBoxProps {
  brand: Brand;
}
interface ReviewBoxProps {
  review: Review;
}

export const DesktopProductBox = ({ product }: ProductBoxProps) => {
  const navigate = useNavigate();
  return (
    <button
      className="flex flex-col gap-y-[16px]"
      onClick={() => {
        track("click_product", {
          productId: product.id,
        });
        navigate({
          pathname: "/product",
          search: `?id=${product.id}`,
        });
      }}
    >
      <div className="border border-gray-10 h-[340px] rounded-[20px] w-full flex items-center justify-center">
        <img
          src={assetsBaseUrl + product.images[0]}
          className="w-full"
          alt=""
        />
      </div>
      <div className="text-left px-[4px] text-[14px] tracking-tight leading-short">
        <div className="mb-[4px] font-medium text-gray-90">
          {product.brand.name}
        </div>
        <div className="font-light text-gray-70 line-clamp-2">
          {product.name}
        </div>
      </div>
      <div className="px-[4px] flex gap-x-[12px] items-center">
        <span className="flex gap-x-[4px] items-center">
          <img
            src={globalImages.icon.star.full}
            className="w-[12px] h-[12px]"
            alt=""
          />
          <span className="text-[12px] font-light tracking-tight leading-short text-gray-70">
            {product.rating}
          </span>
        </span>
        <span className="font-medium tracking-tight leading-short text-gray-90">
          ${((product.msrp ?? 0) / 1300).toFixed(2).toLocaleString()}
        </span>
      </div>
    </button>
  );
};

export const DesktopBrandBox = ({ brand }: BrandBoxProps) => {
  const navigate = useNavigate();
  return (
    <button
      className="flex flex-col gap-y-[16px]"
      onClick={() => {
        track("click_brand", {
          brandId: brand.id,
        });
        navigate({
          pathname: "/brand",
          search: `?id=${brand.id}`,
        });
      }}
    >
      <div className="border border-gray-10 h-[284px] rounded-[20px] w-full flex items-center justify-center">
        <img
          src={assetsBaseUrl + brand.image}
          className="w-full rounded-[20px]"
          alt=""
        />
      </div>
      <div className="flex flex-col gap-y-[8px]">
        <div className="text-left px-[4px] text-[14px] tracking-tight leading-short font-medium text-gray-90">
          {brand.name}
        </div>
      </div>
    </button>
  );
};

export const DesktopReviewBox = ({ review }: ReviewBoxProps) => {
  return (
    <div className="min-w-[484px] h-[148px] bg-gray-10 rounded-[16px] p-[24px] font-light text-gray-70 tracking-tight leading-short">
      <div className="flex items-center justify-between mb-[12px]">
        <span className="">{review.name}</span>
        <DesktopStars rating={review.rating} />
      </div>
      <div className="line-clamp-3">{review.description}</div>
    </div>
  );
};

export const MobileProductBox = ({ product }: ProductBoxProps) => {
  const navigate = useNavigate();
  return (
    <button
      className="flex-1 flex-col gap-y-[16px]"
      onClick={() => {
        track("click_product", {
          productId: product.id,
        });
        navigate({
          pathname: "/product",
          search: `?id=${product.id}`,
        });
      }}
    >
      <div className="border border-gray-10 h-[208px] rounded-[8px] w-full flex items-center justify-center px-[4px] mb-[16px]">
        <img
          src={assetsBaseUrl + product.images[0]}
          className="w-full rounded-[8px]"
          alt=""
        />
      </div>
      <div className="text-left px-[4px] text-[12px] tracking-tight leading-short mb-[16px]">
        <div className="mb-[4px] font-medium text-gray-90">
          {product.brand.name}
        </div>
        <div className="text-[12px] font-light text-gray-70 line-clamp-2">
          {product.name}
        </div>
      </div>
      <div className="px-[4px] flex gap-x-[8px] items-center">
        <span className="flex gap-x-[4px] items-center">
          <img
            src={globalImages.icon.star.full}
            className="w-[12px] h-[12px]"
            alt=""
          />
          <span className="text-[12px] font-light tracking-tight leading-short text-gray-70">
            {product.rating}
          </span>
        </span>
        <span className="text-[14px] font-medium tracking-tight leading-short text-gray-90">
          ${((product.msrp ?? 0) / 1300).toFixed(2).toLocaleString()}
        </span>
      </div>
    </button>
  );
};

export const MobileBrandBox = ({ brand }: BrandBoxProps) => {
  const navigate = useNavigate();
  return (
    <button
      className="flex-1 flex flex-col gap-y-[16px]"
      onClick={() => {
        track("click_brand", {
          brandId: brand.id,
        });
        navigate({
          pathname: "/brand",
          search: `?id=${brand.id}`,
        });
      }}
    >
      <div className="border border-gray-10 aspect-square rounded-[8px] w-full flex items-center justify-center">
        <img
          src={assetsBaseUrl + brand.image}
          className="w-full rounded-[8px]"
          alt=""
        />
      </div>
      <div className="flex flex-col gap-y-[8px]">
        <div className="text-left px-[4px] text-[12px] tracking-tight leading-short font-medium text-gray-90">
          {brand.name}
        </div>
      </div>
    </button>
  );
};

export const MobileReviewBox = ({ review }: ReviewBoxProps) => {
  return (
    <div className="flex flex-col min-w-[328px] bg-gray-10 rounded-[8px] p-[12px] text-[12px] font-light text-gray-70 tracking-tight leading-short">
      <div className="flex items-center justify-between mb-[12px]">
        <span>{review.name}</span>
        <MobileStars rating={review.rating} />
      </div>
      <div className="line-clamp-4">{review.description}</div>
    </div>
  );
};
