import { track } from "@amplitude/analytics-browser";
import { DesktopLayout } from "@components/layouts";

import { getBrandDetail } from "api/brands";
import { postInquiries } from "api/inquiries";
import { assetsBaseUrl } from "common/config";
import {
  ANNUAL_SALES_LIST,
  BUSINESS_TYPE_LIST,
  PRIVACY_POLICY_LINK,
  TERMS_OF_SERVICE_LINK,
} from "common/data";
import globalImages from "common/images";
import { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export const BrandContactDesktop = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const brandId = Number(searchParams.get("id"));

  const [brand, setBrand] = useState<Brand>();

  const [description, setDescription] =
    useState<BrandInquiriesBody["description"]>("");

  const [firstName, setFirstName] =
    useState<BrandInquiriesBody["firstName"]>("");
  const [lastName, setLastName] = useState<BrandInquiriesBody["lastName"]>("");
  const [email, setEmail] = useState<BrandInquiriesBody["email"]>("");
  const [countryList, setCountryList] = useState<any>([]);
  const [country, setCountry] = useState({
    code: "US",
    name: "United States of America",
  });
  const [spreadCountryList, setSpreadCountryList] = useState<boolean>(false);

  const [companyName, setCompanyName] =
    useState<BrandInquiriesBody["companyName"]>("");
  const [companyUrl, setCompanyUrl] =
    useState<BrandInquiriesBody["companyUrl"]>(null);
  const [businessKind, setBusinessKind] =
    useState<BrandInquiriesBody["businessKind"]>(null);
  const [annualSales, setAnnualSales] =
    useState<BrandInquiriesBody["annualSales"]>(null);

  const [spreadBusinessKindList, setSpreadBusinessKindList] =
    useState<boolean>(false);
  const [spreadAnnualSalesList, setSpreadAnnualSalesList] =
    useState<boolean>(false);

  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const [privacyAgreed, setPrivacyAgreed] = useState<boolean>(false);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    track("enter_contact", { brandId });
  }, [brandId]);

  useEffect(() => {
    const fetch = async () => {
      const brandData = await getBrandDetail(brandId);
      if (brandData) {
        setBrand(brandData);
      }
    };

    fetch();
  }, [brandId]);

  useEffect(() => {
    const countryList = Object.entries(countries.getNames("en")).map(
      ([key, value]) => {
        return { code: key, name: value };
      }
    );
    setCountryList(countryList);
  }, []);

  useEffect(() => {
    if (
      description === "" ||
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      companyName === "" ||
      !termsAgreed ||
      !privacyAgreed
    ) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [
    description,
    firstName,
    lastName,
    email,
    companyName,
    companyUrl,
    termsAgreed,
    privacyAgreed,
  ]);

  const _request = async () => {
    if (window.gtag) {
      const { gtag } = window;
      gtag("event", "conversion", {
        send_to: "AW-11145749137/u8bdCJ_5-IQZEJHF2sIp",
      });
    }
    ReactPixel.fbq("track", "Brand Request");

    const body = {
      brandId,
      description,
      sample: false,
      firstName,
      lastName,
      email,
      country: country.code,
      companyName,
      companyUrl,
      businessKind,
      annualSales,
    };
    const response = await postInquiries(body);
    if (response && response.success) {
      track("contact_done", { brandId });
      navigate("/");
    }
  };

  return (
    <DesktopLayout>
      <div className="bg-white">
        <div className="mx-auto pt-[72px] pb-[120px] flex flex-col w-[716px] items-center">
          {/* title */}
          <div className="text-[32px] font-bold leading-tall tracking-tight text-gray-90 mb-[72px]">
            <FormattedMessage id="brand.contact.title" />
          </div>

          {/* brand information */}
          <div className="w-full h-[187px] border-b border-b-gray-20 mb-[36px]">
            <div className="w-full h-[52px] border-y border-y-gray-10 flex items-center justify-center text-[14px] font-light leading-short tracking-tight text-gray-70">
              <FormattedMessage id="brand.contact.column.label" />
            </div>
            <div className="w-full h-[132px] flex items-center">
              <div className="rounded-[8px] border border-gray-10 flex items-center justify-center w-[84px] h-[84px] mr-[16px]">
                <img
                  src={assetsBaseUrl + brand?.image}
                  className="object-contain"
                  alt=""
                />
              </div>
              <span className="font-medium text-[14px] text-gray-70 tracking-tight leading-short">
                {brand?.name}
              </span>
            </div>
          </div>

          <div className="w-full flex flex-col">
            <div className="text-[24px] font-bold text-gray-90 leading-tall tracking-tight mb-[36px]">
              <FormattedMessage id="brand.contact.inquiry.title" />
            </div>

            <div className="mb-[20px]">
              <div className="text-[14px] font-light tracking-tight leading-short text-gray-70 mb-[8px] px-[4px]">
                <FormattedMessage id="product.contact.inquiry.label" />
              </div>
              <textarea
                className="w-full h-[268px] px-[16px] py-[13px] border border-gray-20 rounded-[8px] outline-none font-light leading-short tracking-tight resize-none text-gray-90"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className="h-[1px] bg-gray-20 my-[36px]" />

            {/* customer information */}
            <div style={{ zIndex: 200 }}>
              <div className="text-[24px] font-bold text-gray-90 leading-tall tracking-tight mb-[36px]">
                <FormattedMessage id="product.contact.customer.title" />
              </div>
              {/* name */}
              <div className="flex gap-x-[16px] mb-[20px]">
                <span className="flex-1">
                  <div className="text-[14px] font-light tracking-tight leading-short text-gray-70 mb-[8px] px-[4px]">
                    <FormattedMessage id="product.contact.customer.firstname" />
                  </div>
                  <input
                    className="w-full h-[48px] px-[16px] border border-gray-20 rounded-[8px] outline-none font-light tracking-tight text-gray-90"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </span>
                <span className="flex-1">
                  <div className="text-[14px] font-light tracking-tight leading-short text-gray-70 mb-[8px] px-[4px]">
                    <FormattedMessage id="product.contact.customer.lastname" />
                  </div>
                  <input
                    className="w-full h-[48px] px-[16px] border border-gray-20 rounded-[8px] outline-none font-light tracking-tight text-gray-90"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </span>
              </div>
              {/* e-mail */}
              <div className="w-full mb-[20px]">
                <div className="text-[14px] font-light tracking-tight leading-short text-gray-70 mb-[8px] px-[4px]">
                  <FormattedMessage id="product.contact.customer.email" />
                </div>
                <input
                  className="w-full h-[48px] px-[16px] border border-gray-20 rounded-[8px] outline-none font-light tracking-tight text-gray-90"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {/* country */}
              <div className="w-full">
                <div className="text-[14px] font-light tracking-tight leading-short text-gray-70 mb-[8px] px-[4px]">
                  <FormattedMessage id="product.contact.customer.country" />
                </div>
                <div className="font-light tracking-tight leading-short text-gray-90 relative">
                  <button
                    className="w-full h-[48px] px-[16px] bg-white rounded-[8px] border border-gray-20 flex items-center justify-between"
                    onClick={() => setSpreadCountryList(!spreadCountryList)}
                  >
                    <span>{country.name}</span>
                    <img
                      src={
                        spreadCountryList
                          ? globalImages.chevron.up
                          : globalImages.chevron.down
                      }
                      className="w-[20px] h-[20px]"
                      alt=""
                    />
                  </button>
                  {spreadCountryList && (
                    <div className="absolute w-full max-h-[324px] overflow-y-auto px-[12px] py-[18px] mt-[18px] border border-gray-20 rounded-[8px] flex flex-col gap-y-[1px] bg-white">
                      {countryList.map((country: any, index: number) => (
                        <button
                          key={index}
                          onClick={() => {
                            setCountry(country);
                            setSpreadCountryList(false);
                          }}
                          className="min-h-[48px] flex items-center rounded-[8px] px-[16px] hover:bg-gray-10"
                        >
                          {country.name}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="h-[1px] bg-gray-20 my-[36px]" />

            {/* business information */}
            <div className="mb-[36px]">
              <div className="text-[24px] font-bold text-gray-90 leading-tall tracking-tight mb-[36px]">
                <FormattedMessage id="product.contact.business.title" />
              </div>
              {/* company */}
              <div className="flex gap-x-[16px] mb-[20px]">
                <span className="flex-1">
                  <div className="text-[14px] font-light tracking-tight leading-short text-gray-70 mb-[8px] px-[4px]">
                    <FormattedMessage id="product.contact.business.company" />
                  </div>
                  <input
                    className="w-full h-[48px] px-[16px] border border-gray-20 rounded-[8px] outline-none font-light tracking-tight text-gray-90"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </span>
                <span className="flex-1">
                  <div className="flex items-center mb-[8px] px-[4px] gap-x-[4px]">
                    <span className="text-[14px] font-light tracking-tight leading-short text-gray-70">
                      <FormattedMessage id="product.contact.business.url" />
                    </span>
                    <span className="text-[12px] font-light tracking-tight leading-short text-error">
                      (<FormattedMessage id="contact.optional.label" />)
                    </span>
                  </div>
                  <input
                    className="w-full h-[48px] px-[16px] border border-gray-20 rounded-[8px] outline-none font-light tracking-tight text-gray-90"
                    value={companyUrl ?? ""}
                    onChange={(e) => setCompanyUrl(e.target.value)}
                  />
                </span>
              </div>
              {/* business type */}
              <div className="w-full mb-[20px]">
                <div className="flex items-center mb-[8px] px-[4px] gap-x-[4px]">
                  <span className="text-[14px] font-light tracking-tight leading-short text-gray-70">
                    <FormattedMessage id="product.contact.business.type" />
                  </span>
                  <span className="text-[12px] font-light tracking-tight leading-short text-error">
                    (<FormattedMessage id="contact.optional.label" />)
                  </span>
                </div>
                <div className="font-light tracking-tight leading-short text-gray-90 relative">
                  <button
                    className="w-full h-[48px] px-[16px] bg-white rounded-[8px] border border-gray-20 flex items-center justify-between"
                    onClick={() =>
                      setSpreadBusinessKindList(!spreadBusinessKindList)
                    }
                  >
                    <span>{businessKind}</span>
                    <img
                      src={
                        spreadBusinessKindList
                          ? globalImages.chevron.up
                          : globalImages.chevron.down
                      }
                      className="w-[20px] h-[20px]"
                      alt=""
                    />
                  </button>
                  {spreadBusinessKindList && (
                    <div
                      className="absolute w-full overflow-y-auto px-[12px] py-[18px] mt-[18px] border border-gray-20 rounded-[8px] flex flex-col gap-y-[1px] bg-white"
                      style={{ zIndex: 150 }}
                    >
                      {BUSINESS_TYPE_LIST.map((type: string, index: number) => (
                        <button
                          key={index}
                          onClick={() => {
                            setBusinessKind(type);
                            setSpreadBusinessKindList(false);
                          }}
                          className="min-h-[48px] flex items-center rounded-[8px] px-[16px] hover:bg-gray-10"
                        >
                          {type}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {/* annual sales */}
              <div className="w-full">
                <div className="flex items-center mb-[8px] px-[4px] gap-x-[4px]">
                  <span className="text-[14px] font-light tracking-tight leading-short text-gray-70">
                    <FormattedMessage id="product.contact.business.sales" />
                  </span>
                  <span className="text-[12px] font-light tracking-tight leading-short text-error">
                    (<FormattedMessage id="contact.optional.label" />)
                  </span>
                </div>
                <div className="font-light tracking-tight leading-short text-gray-90 relative">
                  <button
                    className="w-full h-[48px] px-[16px] bg-white rounded-[8px] border border-gray-20 flex items-center justify-between"
                    onClick={() =>
                      setSpreadAnnualSalesList(!spreadAnnualSalesList)
                    }
                  >
                    <span>{annualSales}</span>
                    <img
                      src={
                        spreadAnnualSalesList
                          ? globalImages.chevron.up
                          : globalImages.chevron.down
                      }
                      className="w-[20px] h-[20px]"
                      alt=""
                    />
                  </button>
                  {spreadAnnualSalesList && (
                    <div className="absolute w-full overflow-y-auto px-[12px] py-[18px] mt-[18px] border border-gray-20 rounded-[8px] flex flex-col gap-y-[1px] bg-white">
                      {ANNUAL_SALES_LIST.map((sales: string, index: number) => (
                        <button
                          key={index}
                          onClick={() => {
                            setAnnualSales(sales);
                            setSpreadAnnualSalesList(false);
                          }}
                          className="min-h-[48px] flex items-center rounded-[8px] px-[16px] hover:bg-gray-10"
                        >
                          {sales}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex gap-x-[32px] mb-[72px]">
            <span className="flex items-center">
              <button onClick={() => setTermsAgreed(!termsAgreed)}>
                <img
                  src={
                    termsAgreed
                      ? globalImages.checkbox.square.active
                      : globalImages.checkbox.square.inactive
                  }
                  className="w-[24px] h-[24px] mr-[4px]"
                  alt=""
                />
              </button>
              <button
                onClick={() => window.open(TERMS_OF_SERVICE_LINK)}
                className="font-light text-gray-70 tracking-tight leading-short"
              >
                <FormattedMessage id="product.contact.agree-1" />
              </button>
            </span>
            <span className="flex items-center">
              <button onClick={() => setPrivacyAgreed(!privacyAgreed)}>
                <img
                  src={
                    privacyAgreed
                      ? globalImages.checkbox.square.active
                      : globalImages.checkbox.square.inactive
                  }
                  className="w-[24px] h-[24px] mr-[4px]"
                  alt=""
                />
              </button>
              <button
                onClick={() => window.open(PRIVACY_POLICY_LINK)}
                className="font-light text-gray-70 tracking-tight leading-short"
              >
                <FormattedMessage id="product.contact.agree-2" />
              </button>
            </span>
          </div>

          <button
            onClick={_request}
            disabled={buttonDisabled}
            className="h-[56px] px-[36px] rounded-[100px] bg-primary text-white font-medium leading-short tracking-tight flex items-center justify-center"
          >
            <FormattedMessage id="brand.contact.request.label" />
          </button>
        </div>
      </div>
    </DesktopLayout>
  );
};
