import {
  COOKIE_POLICY_LINK,
  PRIVACY_POLICY_LINK,
  TERMS_OF_SERVICE_LINK,
} from "common/data";
import globalImages from "common/images";

const DesktopFooter = () => {
  return (
    <div className="bg-white w-[1280px]">
      <div className="px-[48px] py-[16px] border-y border-y-gray-10 flex items-center text-[15px] font-medium leading-short tracking-tight">
        <button
          className="text-primary"
          onClick={() => window.open(TERMS_OF_SERVICE_LINK)}
        >
          Terms of Service
        </button>
        <div className="w-[1px] h-[10px] bg-gray-20 mx-[20px]" />
        <button
          className="text-gray-80"
          onClick={() => window.open(PRIVACY_POLICY_LINK)}
        >
          Privacy Policy
        </button>
        <div className="w-[1px] h-[10px] bg-gray-20 mx-[20px]" />
        <button
          className="text-gray-80"
          onClick={() => window.open(COOKIE_POLICY_LINK)}
        >
          Cookie Policy
        </button>
      </div>
      <div className="pt-[24px] pb-[36px] px-[48px]">
        <img src={globalImages.logo} className="w-[130px] mb-[18px]" alt="" />
        <div className="flex items-center">
          <span className="text-[13px] font-light text-gray-70">
            contact@postalpine.com
          </span>
          <div className="w-[1px] h-[10px] bg-gray-20 mx-[20px]" />
          <span className="text-[13px] font-light text-gray-70">
            ⓒ Postalpine Inc. All rights reserved.
          </span>
        </div>
      </div>
    </div>
  );
};

const MobileFooter = () => {
  return (
    <div className="w-full bg-white">
      <div className="border-y border-y-gray-10">
        <div className="px-[16px] h-[49px] flex items-center justify-center gap-x-[20px] text-[12px] font-medium leading-short tracking-tight">
          <button
            className="text-primary"
            onClick={() => window.open(TERMS_OF_SERVICE_LINK)}
          >
            Terms of Service
          </button>
          <div className="w-[1px] h-[10px] bg-gray-20" />
          <button
            className="text-gray-80"
            onClick={() => window.open(PRIVACY_POLICY_LINK)}
          >
            Privacy Policy
          </button>
          <div className="w-[1px] h-[10px] bg-gray-20" />
          <button
            className="text-gray-80"
            onClick={() => window.open(COOKIE_POLICY_LINK)}
          >
            Cookie Policy
          </button>
        </div>
      </div>
      <div className="py-[32px] px-[16px]">
        <img src={globalImages.logo} className="w-[130px] mb-[18px]" alt="" />
        <div className="flex flex-col gap-y-[8px] text-[13px] font-light tracking-tight leading-short text-gray-70">
          <div>contact@postalpine.com</div>
          <div>ⓒ Postalpine Inc. All rights reserved.</div>
        </div>
      </div>
    </div>
  );
};

export { DesktopFooter, MobileFooter };
